import { RichText } from "@sitecore-jss/sitecore-jss-react";
import axios from "axios";
import Enumerable from "linq";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  setBookmarkedOffer,
  setBookmarkedStore,
  setBookmarkedRestaurant,
  setBookmarkedEvent
} from '../../redux/user/actions';
import { GetRenewedAccessToken, URWindowLocation, closeFlip, flip, isTokenExpired, gtmFuncRemoveFromFavorite, fnGtmOfferClick, offersTooltipOnHover } from '../Js-CommonFunctions';
import { GoBackToMenu, } from '../MyAccount/MyAccountScripts';
import { baseModel } from "../../RouteHandler";

export default function MyFavorites({ fields }) {

  const [allOffersCount, setAllOffersCount] = useState(0);
  const [allEventsCount, setAllEventsCount] = useState(0);
  const [allStoresCount, setAllStoresCount] = useState(0);
  const [allRestaurantsCount, setAllRestaurantsCount] = useState(0);

  const [offersCentersCount, setOffersCentersCount] = useState(0);
  const [eventsCentersCount, setEventsCentersCount] = useState(0);
  const [storesCentersCount, setStoresCentersCount] = useState(0);
  const [restaurantsCentersCount, setRestaurantsCentersCount] = useState(0);

  const [allBookmarks, setAllBookmarks] = useState([]);

  var visitorId = useSelector((state) => state.user.visitorId)
  var accessToken = useSelector((state) => state.user.accessToken)
  var refreshToken = useSelector((state) => state.user.refreshToken)
  const dispatch = useDispatch();

  useEffect(() => {
    if (fields !== undefined && fields !== null && fields !== "") {

      setAllOffersCount(fields.allOffersCount);
      setAllEventsCount(fields.allEventsCount);
      setAllStoresCount(fields.allStoresCount);
      setAllRestaurantsCount(fields.allRestaurantsCount);
      setOffersCentersCount(fields.offersCentersCount);
      setEventsCentersCount(fields.eventsCentersCount);
      setStoresCentersCount(fields.storesCentersCount);
      setRestaurantsCentersCount(fields.restaurantsCentersCount);
      setAllBookmarks(fields.allFavorites);
    }
  }, []);

  useEffect(() => {
    offersTooltipOnHover();
  }, [allBookmarks])
  function DeleteBookmark(e) {
    if (isTokenExpired()) {
      var args = [e];
      GetRenewedAccessToken(RemoveBookmark, args);
    } else {
      RemoveBookmark(e, visitorId, accessToken, refreshToken);
    }
  }

  function RemoveBookmark(e, visitorId, accessToken, refreshToken) {

    var $currentItem = e.target;
    var bookmarkId = $currentItem.getAttribute("data-bookmarkid").valueOf();
    var bookmarkType = $currentItem.getAttribute("data-bookmarktype").valueOf();
    var bookmarkName = $currentItem.getAttribute("data-bookmarkname") !== null && $currentItem.getAttribute("data-bookmarkname").valueOf();

    axios({
      method: "post",
      url: "/api/sitecore/visitorapi/DeleteVisitorBookmark",
      data: {
        bookmarkId: bookmarkId,
        visitorId: visitorId,
        accessToken: accessToken,
        refreshToken: refreshToken,
      },
      async: false,
    })
      .then(function (response) {
        if (response.data === true) {
          let bookmark = "";
          if (bookmarkType === "deal") {

            const filteredOffers = allBookmarks.map(obj => {
              return { ...obj, deals: obj.deals.filter(x => x.bookmarkId !== bookmarkId) };
            });
            const offersCenters = filteredOffers.filter(function (x) { return x.deals.length > 0 }).length;

            let updatedBookmark = filteredOffers.map(t => {
              t.deals.map(obj => {
                if (bookmark === "") {
                  bookmark = obj.bookmarkLabel;
                }
                else {
                  bookmark = bookmark + "," + obj.bookmarkLabel;
                }
              })
            })
            dispatch(setBookmarkedOffer(bookmark));
            setAllBookmarks(filteredOffers);
            setAllOffersCount(allOffersCount - 1);
            setOffersCentersCount(offersCenters);
          } else if (bookmarkType === "news") {

            const filteredEvents = allBookmarks.map(obj => {
              return { ...obj, events: obj.events.filter(function (x) { return x.bookmarkId !== bookmarkId }) };
            });
            const eventsCenters = filteredEvents.filter(function (x) { return x.events.length > 0 }).length;
            let updatedBookmark = filteredEvents.map(t => {
              t.events.map(obj => {
                if (bookmark === "") {
                  bookmark = obj.bookmarkLabel;
                }
                else {
                  bookmark = bookmark + "," + obj.bookmarkLabel;
                }
              })
            })
            dispatch(setBookmarkedEvent(bookmark));
            setAllBookmarks(filteredEvents);
            setAllEventsCount(allEventsCount - 1);
            setEventsCentersCount(eventsCenters);

          } else if (bookmarkType === "store") {

            const filteredStores = allBookmarks.map(obj => {
              return { ...obj, stores: obj.stores.filter(function (x) { return x.bookmarkId !== bookmarkId }) };
            });
            const storesCenters = filteredStores.filter(function (x) { return x.stores.length > 0 }).length;
            let updatedBookmark = filteredStores.map(t => {
              t.stores.map(obj => {
                if (bookmark === "") {
                  bookmark = obj.bookmarkLabel;
                }
                else {
                  bookmark = bookmark + "," + obj.bookmarkLabel;
                }
              })
            })
            dispatch(setBookmarkedStore(bookmark));
            setAllBookmarks(filteredStores);
            setAllStoresCount(allStoresCount - 1);
            setStoresCentersCount(storesCenters);

          } else if (bookmarkType === "restaurant") {

            const filteredRest = allBookmarks.map(obj => {
              return { ...obj, restaurants: obj.restaurants.filter(function (x) { return x.bookmarkId !== bookmarkId }) };
            });
            const restaurantsCenters = filteredRest.filter(function (x) { return x.restaurants.length > 0 }).length;
            let updatedBookmark = filteredRest.map(t => {
              t.restaurants.map(obj => {
                if (bookmark === "") {
                  bookmark = obj.bookmarkLabel;
                }
                else {
                  bookmark = bookmark + "," + obj.bookmarkLabel;
                }
              })
            })
            dispatch(setBookmarkedRestaurant(bookmark));
            setAllBookmarks(filteredRest);
            setAllRestaurantsCount(allRestaurantsCount - 1);
            setRestaurantsCentersCount(restaurantsCenters);
          }
          gtmFuncRemoveFromFavorite(bookmarkName, bookmarkType);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  if (fields !== undefined && fields !== null && fields !== "") {
    return (
      <>
        <div className="basic-benefits-container d-md-none d-lg-none d-xl-none bg-white">
          <div className="container modalContainer p-0">
            <nav className="navbar benefits-header-height px-lg-1 row m-0 py-2">
              <div className="col-2 text-center px-0">
                <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black fs-3"
                  href="javascript:void(0);" onClick={() => { GoBackToMenu("my-favorites") }}></a>
              </div>
              <div className="col-8 p-0 text-center">
                <div className="text-center benefits-subtitle">
                  {fields.title}
                </div>
              </div>
              <div className="col-2 text-center px-0">
              </div>
            </nav>
          </div>
        </div>

        <div className="account-personal-informations d-none d-lg-block">{fields.title}</div>
        <div className="favourites-row filter-wrap new" id="FilterWrap">

          <ul className="nav mob-menu nav-tabs" role="tablist">
            <li className="nav-item text-center">
              <a data-bs-toggle="tab" href="#myoffers" className="nav-link gtmBookmarkTabs genericTab active" role="tab">
                <span className="picto favorites-icon-mob icon-offers d-block d-lg-none"></span>
                <span className=" d-block navigation-title d-lg-none">{fields.myOffers_Label + (allOffersCount > 0 ? " (" + allOffersCount + ")" : "")}</span>
                <span className="d-lg-block d-none">{fields.myOffers_Label}</span>
              </a>
            </li>
            <li className="nav-item text-center">
              <a data-bs-toggle="tab" href="#myevents" className="nav-link gtmBookmarkTabs genericTab" role="tab">
                <span className="picto favorites-icon-mob icon-ico-placeholder-nonews d-block d-lg-none"></span>
                <span className="d-block navigation-title d-lg-none">{fields.myEvents_Label + (allEventsCount > 0 ? " (" + allEventsCount + ")" : "")}</span>
                <span className="d-lg-block d-none">{fields.myEvents_Label}</span>
              </a>
            </li>
            <li className="nav-item text-center">
              <a data-bs-toggle="tab" href="#mystores" className="nav-link gtmBookmarkTabs genericTab" role="tab">
                <span className="picto favorites-icon-mob icon-ico-ph-shops d-block d-lg-none"></span>
                <span className="d-block navigation-title d-lg-none">{fields.myStores_Label + (allStoresCount > 0 ? " (" + allStoresCount + ")" : "")}</span>
                <span className="d-lg-block d-none">{fields.myStores_Label}</span>
              </a>
            </li>
            <li className="nav-item text-center">
              <a data-bs-toggle="tab" href="#myrestaurants" className="nav-link gtmBookmarkTabs genericTab" role="tab">
                <span className="picto favorites-icon-mob icon-srv-restaurant d-block d-lg-none"></span>
                <span className="d-block navigation-title d-lg-none">{fields.myRestaurants_Label + (allRestaurantsCount > 0 ? " (" + allRestaurantsCount + ")" : "")}</span>
                <span className="d-lg-block d-none">{fields.myRestaurants_Label}</span>
              </a>
            </li>
          </ul>

          <div className="tab-content myfavourite-tab-content pe-0">

            <div id="myoffers" className="tab-pane myoffers fade in active show" role="tabpanel">
              {allBookmarks.map((item) => {
                if (item.deals.length > 0) {
                  return (
                    <>
                      {offersCentersCount > 1 &&
                        <div className="my-fav-sub-head">{item.centerName}</div>
                      }
                      <section className="logged-user d-md-block">
                        <div className="container p-lg-0">
                          <div className="offers personal-offers">
                            <div className="row commercial-offers  contentSection otherOffers">
                              {item.deals.map((deal, index) => {
                                let dealInfo = deal.dealInfo;
                                if (dealInfo === null) {
                                  return <></>;
                                }

                                let offerLogo = "";
                                let offerLink = "";
                                let strCommercialOperation =
                                  dealInfo.categoryIds !== null &&
                                    dealInfo.categoryIds !== "" &&
                                    Enumerable.from(dealInfo.categoryIds).any()
                                    ? Enumerable.from(dealInfo.categoryIds).firstOrDefault().value
                                    : 0;
                                var subtitle = { value: dealInfo.subtitle };
                                var description = { value: dealInfo.description };
                                var termsAndConditions = { value: dealInfo.termsAndConditions };

                                if (strCommercialOperation !== fields.commercialTypeExternal) {

                                  offerLogo = dealInfo._links?.retailerLogo?.href !== null && dealInfo._links.retailerLogo.href.includes(fields.imgCloudinary)
                                    ? dealInfo.scaledImageCommercial
                                    : dealInfo.addMaxHeightImageCommercial;

                                  if (offerLogo === "") {
                                    offerLogo = deal.storeImageUrl;
                                  }

                                  let pageName =
                                    deal.storeTypeId === fields.typeStore
                                      ? item.storePage
                                      : deal.storeTypeId === fields.typeRestaurant
                                        ? item.restaurantPage
                                        : "";

                                  offerLink = item.virtualPath + "/" + pageName + "/" + dealInfo.storeUrl;

                                } else {
                                  offerLogo = dealInfo.externalOfferLogo !== null &&
                                    dealInfo.externalOfferLogo.includes(fields.imgCloudinary)
                                    ? dealInfo.scaledImage
                                    : dealInfo.addMaxHeightImageImage;

                                  offerLink = dealInfo.externalUrl;
                                }
                                return (
                                  <div key={index} className="col-lg-4 col-12  flip-card contentBlock"
                                    onClick={(e) => { tileClick(e, offerLink, true); fnGtmOfferClick(deal.dealInfo.title, deal.storeName, deal.retailerName, deal.eligibilityText, false, item.isWestfieldCenter) }} data-testid="testClick1">

                                    <div className="offers-thumbnail thumbnail align-items-start flip-front position-relative border-0 rounded-0 p-0 shadow-none remove-flip">

                                      <a href="javascript:void(0);" aria-label={deal?.bookmarkId} onClick={(e) => DeleteBookmark(e)}
                                        className="icon-heart-unfill-rw account-bookmark bookmark picto  ava-wishlist position-absolute"
                                        data-bookmarkid={deal.bookmarkId} data-bookmarkname={deal.bookmarkLabel} data-bookmarktype="deal" data-testid="testClick2">
                                      </a>
                                      {/* {((dealInfo.description !== null && dealInfo.description !== "") ||
                                        (dealInfo.termsAndConditions !== null && dealInfo.termsAndConditions !== "")) &&
                                        <div className="account-offers-btn-wrap-back tooltip-btn-wrap position-absolute rounded-circle js-tooltip">
                                         
                                          <span className="picto icon-infos gtmFlipOffer js-tooltip" />
                                        </div>
                                      } */}
                                      <a href="javascript:void(0);" aria-label={dealInfo?.title} className="offers-header-back-img bg-white header w-100  d-flex cursor-default">
                                        {/* <span className="helper"></span> */}
                                        {(offerLogo !== null && offerLogo !== "") &&
                                          <img src={offerLogo} className="d-flex home-offers-image"
                                            alt={dealInfo.title} title={dealInfo.title} />
                                        }
                                        {item.isWestfieldCenter ?
                                          (deal.eligibilityText != null && deal.eligibilityText != "" && baseModel.westfieldClubLogo?.value !== null && baseModel.westfieldClubLogo?.value !== "" && baseModel.westfieldClubLogo?.value.src !== null && baseModel.westfieldClubLogo?.value?.src !== "" &&
                                            <div className='westfield-club-logo d-flex align-items-center justify-content-center w-100'>
                                              <img className="d-flex wclub-img" src={baseModel.westfieldClubLogo?.value?.src} loading="lazy"
                                                alt={baseModel.westfieldClubLogo?.value?.alt} title="club" />
                                            </div>
                                          ) :
                                          (deal.eligibilityText != null && deal.eligibilityText != "" && baseModel.clubLogo?.value !== null && baseModel.clubLogo?.value !== "" && baseModel.clubLogo?.value.src !== null && baseModel.clubLogo?.value?.src !== "" &&
                                            <div className='westfield-club-logo d-flex align-items-center justify-content-center w-100'>
                                              <img className="d-flex wclub-img" src={baseModel.clubLogo?.value?.src} loading="lazy"
                                                alt={baseModel.clubLogo?.value?.alt} title="club" />
                                            </div>
                                          )
                                        }
                                      </a>

                                      <div className="offers-caption cursor-default">
                                        <div className="text-start retailer-name my-1">{deal.retailerName}</div>
                                        <div className="d-flex align-items-center">
                                          <div className="offer-title-new my-1">{deal.storeName}</div>
                                          {((dealInfo.description !== null && dealInfo.description !== "") ||
                                            (dealInfo.termsAndConditions !== null && dealInfo.termsAndConditions !== "")) &&
                                            <div className="picto icon-information-rw fs-5 position-relative rounded-circle js-tooltip ms-2 cursor-pointer">
                                            </div>
                                          }
                                        </div>
                                        {/* <div className="date-offer text-start my-1">Du 22février au 29 avril</div> */}
                                        {((dealInfo.sticker !== null || dealInfo.sticker !== undefined && dealInfo.sticker !== "") || (dealInfo.subtitle !== null || dealInfo.subtitle !== undefined && dealInfo.subtitle !== "")) &&
                                          <div className="d-flex align-items-start  discount-block my-2">
                                            {dealInfo.sticker !== null || dealInfo.sticker !== undefined && dealInfo.sticker !== "" &&
                                              <div className="discount-new rounded-3">{dealInfo.sticker}</div>
                                            }
                                            {dealInfo.subtitle !== null || dealInfo.subtitle !== undefined && dealInfo.subtitle !== "" &&
                                              <div className="discount-description text-start">
                                                <RichText field={subtitle} />
                                              </div>
                                            }
                                          </div>
                                        }
                                        {/* <a className="offer-status p-0"><span className="text-uppercase">Valentines
                                          Day</span></a> */}
                                      </div>
                                    </div>

                                    <div className="offers-thumbnail align-items-start bg-white flip-back position-relative border-0 rounded-0 p-1 flipped" style={{ display: "none" }}>
                                      <span className="flip-message">
                                        {(dealInfo.description !== null && dealInfo.description !== "") ? (
                                          <RichText field={description} />

                                        ) : (dealInfo.termsAndConditions !== null && dealInfo.termsAndConditions !== "") ? (
                                          <RichText field={termsAndConditions} />
                                        ) : (
                                          <></>
                                        )
                                        }
                                      </span>
                                      <span className="flip-close picto icon-close-circle-rw"> </span>
                                      {/* <a className="icon-cross m-auto my-offers-icon open-popin picto" href="javascript:void(0);"></a>
                                      <div className="my-favourate-description">
                                        {(dealInfo.description !== null && dealInfo.description !== "") ? (
                                          <RichText field={description} />

                                        ) : (dealInfo.termsAndConditions !== null && dealInfo.termsAndConditions !== "") ? (
                                          <RichText field={termsAndConditions} />
                                        ) : (
                                          <></>
                                        )
                                        }
                                      </div> */}
                                    </div>

                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  )
                }
              })}
              {allOffersCount > 0 ? (
                <div className="note d-lg-block d-xl-block d-none">{fields.cesOfferText + " "}
                  {/* <a href={"/" + fields.termsAndConditionsPage} aria-label={fields?.termsAndConditionsPage} className="text-underline note">
                    {fields.termsAndConditionsText}
                  </a> */}
                </div>
              ) : (
                <div className="favorites-nocontent-container mb-3">
                  <div className="favorites-nocontent">
                    <RichText field={{ value: fields.noOfferText }} />
                  </div>
                </div>
              )}
            </div>

            <div id="myevents" className="tab-pane myevents fade " role="tabpanel">
              {allBookmarks.map((item, index) => {
                if (item.events.length > 0) {
                  return (
                    <>
                      {eventsCentersCount > 1 &&
                        <div className="my-fav-sub-head">{item.centerName}</div>
                      }
                      <section className="logged-user d-md-block">
                        <div className="container p-lg-0">
                          <div className="offers personal-offers">
                            <div className="row">
                              {item.events.map((event) => {
                                let newdetailsUrl = item.virtualPath + "/" + item.newsDetailPage + "/" + event?.encodeNewsUrl;
                                let resultDisplayName = { value: event.display_Name };
                                return (
                                  <div key={index} className="col-12 col-md-4 mb-3"
                                    onClick={(e) => tileClick(e, (newdetailsUrl), false)} data-testid="testClick3">
                                    <div className="col grid-item grid-withpicture bg-transparant shadow-none border-0 position-relative pb-0">
                                      <a href="javascript:void(0);" aria-label={event?.bookmarkId} onClick={(e) => DeleteBookmark(e)}
                                        className="icon-heart-unfill-rw account-bookmark bookmark picto position-absolute"
                                        data-bookmarkid={event.bookmarkId} data-bookmarkname={event.bookmarkLabel} data-bookmarktype="news" data-testid="testClick4">
                                      </a>
                                      <div className=" grid-new-item grid-withpicture grid-no-home position-relative  ">
                                        <div className="position-relative grid-picture m-0 p-0 rounded-3 ">
                                          {event.imageUrl && (
                                            <img src={event.imageUrl} className="rounded-3"
                                              title={event.display_Name} alt={event.display_Name} />
                                          )}
                                        </div>
                                        <a href={newdetailsUrl} aria-label={event?.display_Name} className="position-absolute w-100 h-100 block-link stretched-link">

                                        </a>

                                        <div className="grid-new-content text-start grid-content-mobile">
                                          {/* <a href="javascript:void(0);" aria-label={event?.retailerName} className="my-events-icon d-lg-block d-none d-xl-block icon-center fs-5"></a> */}
                                          {event.retailerName !== null &&
                                            event.retailerName !== "" && (
                                              <div className="retailer-name my-1">
                                                {event.retailerName}
                                              </div>
                                            )}
                                          {/* <div className="date ">
                                            <div>31 octobre 2023 à 22h00</div>
                                          </div> */}
                                          <div className="grid-new-title py-1">
                                            <RichText field={resultDisplayName} />
                                          </div>

                                          <div className="">
                                            <a href={newdetailsUrl}
                                              aria-label={event?.externalUrlText}
                                              className="grid-new-cta">
                                              {event.externalUrlText}
                                            </a>
                                            <span className="picto icon-arrow-square-red-right-rw align-middle px-0 fs-5" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  )
                }
              })}
              {allEventsCount === 0 &&
                <div className="favorites-nocontent-container mb-3">
                  <div className="favorites-nocontent">
                    <RichText field={{ value: fields.noEventText }} />
                  </div>
                </div>
              }
            </div>

            <div id="mystores" className="tab-pane mybrands" role="tabpanel">
              {allBookmarks.map((item) => {
                if (item.stores.length > 0) {
                  return (
                    <>
                      {storesCentersCount > 1 &&
                        <div className="my-fav-sub-head">{item.centerName}</div>
                      }
                      <section className="logged-user d-md-block">
                        <div className="container p-lg-0">
                          <div className="offers personal-offers">
                            <div className="row">
                              {item.stores.map((store, storeIndex) => {
                                let url = "";
                                if (store.storeTypeIds !== null && store.storeTypeIds.includes(fields.typeStore)) {
                                  url = "/" + item.storePage + "/" + store.url1 + "/" + store.storeId.toString();
                                }
                                return (
                                  <div key={storeIndex} className="col-lg-4 mob-fav-pd-0 d-flex justify-content-center mb-lg-4 col-12 ps-0 pe-lg-2 pe-0 favorites-padding-right"
                                    onClick={(e) => tileClick(e, (item.virtualPath + url), false)} data-testid="testClick5">
                                    <div className="offers-thumbnail my-brands-thumbnail position-relative border-0 rounded-0 p-0">
                                      <a href="javascript:void(0);" onClick={(e) => DeleteBookmark(e)} className="icon-heart-unfill-rw account-bookmark bookmark picto  ava-wishlist position-absolute"
                                        data-bookmarkid={store.bookmarkId} data-bookmarkname={store.bookmarkLabel} data-bookmarktype="store" data-testid="testClick6">
                                      </a>

                                      <a href={item.virtualPath + url} className="offers-header-back-img bg-white d-block">
                                        <span className="helper"></span>
                                        {(store._links.logo.href !== null && store._links.logo.href !== "") ?

                                          <img src={store._links.logo.href} className="my-event-img d-inline-block m-0" alt={store.name} title={store.name} />
                                          :
                                          <img src="#" className="my-event-img d-inline-block m-0" alt={store.name} title={store.name} />
                                        }
                                      </a>

                                      <div className="offers-caption my-brands-offer-caption d-flex justify-content-center text-center">
                                        <div className="btn-wrap my-event-btn ">
                                          <a href={item.virtualPath + url} aria-label={fields?.seeTheStore} className="link-button no-underline my-brands-btn-text btn-dark-transparent">
                                            {fields.seeTheStore}</a>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  )
                }
              })}
              {allStoresCount === 0 &&
                <div className="favorites-nocontent-container mb-3">
                  <div className="favorites-nocontent">
                    <RichText field={{ value: fields.noStoreText }} />
                  </div>
                </div>
              }
            </div>

            <div id="myrestaurants" className="tab-pane mybrands" role="tabpanel" >
              {allBookmarks.map((item, index) => {
                if (item.restaurants.length > 0) {
                  return (
                    <>
                      {restaurantsCentersCount > 1 &&
                        <div className="my-fav-sub-head">{item.centerName}</div>
                      }
                      <section className="logged-user d-md-block">
                        <div className="container p-lg-0">
                          <div className="offers personal-offers">
                            <div className="row">
                              {item.restaurants.map((restaurant) => {
                                var url = "";
                                if (restaurant.storeTypeIds !== null && restaurant.storeTypeIds.includes(fields.typeRestaurant)) {
                                  url = "/" + item.restaurantPage + "/" + restaurant.url1 + "/" + restaurant.storeId.toString();
                                }
                                return (
                                  <div key={index} className="col-lg-4 mob-fav-pd-0 d-flex justify-content-center mb-lg-4 col-12 ps-0 pe-lg-2 pe-0 favorites-padding-right"
                                    onClick={(e) => tileClick(e, (item.virtualPath + url), false)} data-testid="testClick7">
                                    <div className="offers-thumbnail my-brands-thumbnail position-relative border-0 rounded-0 p-0">
                                      <a href="javascript:void(0);" onClick={(e) => DeleteBookmark(e)} className="icon-heart-unfill-rw account-bookmark bookmark picto  ava-wishlist position-absolute"
                                        data-bookmarkid={restaurant.bookmarkId} data-bookmarkname={restaurant.bookmarkLabel} data-bookmarktype="restaurant" data-testid="testClick8">
                                      </a>

                                      <a href={item.virtualPath + url} className="offers-header-back-img bg-white d-block">
                                        <span className="helper"></span>
                                        {(restaurant._links.logo.href !== null && restaurant._links.logo.href !== "") ?

                                          <img src={restaurant._links.logo.href} className="my-event-img d-inline-block m-0" alt={restaurant.name} title={restaurant.name} />
                                          :
                                          <img src="#" className="my-event-img d-inline-block m-0" alt={restaurant.name} title={restaurant.name} />
                                        }
                                      </a>

                                      <div className="offers-caption my-brands-offer-caption d-flex justify-content-center text-center">
                                        <div className="btn-wrap my-event-btn">
                                          <a href={item.virtualPath + url} className="link-button no-underline my-brands-btn-text btn-dark-transparent">
                                            {fields.seeTheStore}</a>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </section>
                    </>
                  )
                }
              })}
              {allRestaurantsCount === 0 &&
                <div className="favorites-nocontent-container mb-3">
                  <div className="favorites-nocontent">
                    <RichText field={{ value: fields.noRestaurantText }} />
                  </div>
                </div>
              }
            </div>

          </div>
        </div>

      </>
    )
  }
  else {
    return <></>
  }
}

function tileClick(e, linkToRedirect, isOffer) {
  var element = e.target;
  if (element.classList.contains("icon-heart-unfill-rw")) {
    //if clicked on the 'heart' icon, Unbookmark the item and do not redirect
    return;
  }
  if (element.classList.contains("js-tooltip")) {
    //if clicked on the 'i' icon, flip the block to show the description
    flip(e);
    return;
  }
  if (element.classList.contains("icon-close-circle-rw")) {
    //close of the flip to hide the description and show offer block
    var displayStyle = "block";
    closeFlip(e, displayStyle);
    return;
  }
  if (isOffer) {
    linkToRedirect = linkToRedirect + "#offers"
  }
  if (!isOffer)
    URWindowLocation(linkToRedirect);
}