
import loadable from "@loadable/component";
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from 'react';
import EasyParking from '../EasyParking';
import { updatePartnerDetail } from '../MyAccount/MyAccountScripts';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setParkingClubActiveUser } from "../../redux/user/actions";
import { isTokenExpired, GetRenewedAccessToken, fnGTMSaveVehicle } from "../Js-CommonFunctions";
import { baseModel } from "../../RouteHandler";
const GoogleMapsCenterTest = loadable(() => import("../GoogleMapsCenter"));
const ServicePillar = loadable(() => import("../ServicePillar"));
const HowToEnjoyIt = loadable(() => import("../HowToEnjoyIt"));

export default function ServiceDetails({ fields }) {
  const dispatch = useDispatch();
  var visitorId = useSelector((state) => state.user.visitorId)
  const accessToken = useSelector((state) => state.user.accessToken);
  const refreshToken = useSelector((state) => state.user.refreshToken);
  const parkingPartners = useSelector((state) => state.user.parkingPartners);
  const smartParkerCenters = useSelector((state) => state.user.smartParkerCenters);
  var parkingClubActiveUser = useSelector((state) => state.user.parkingClubActiveUser);
  let workerCenter = useSelector((state) => state.user.workerCenter);

  useEffect(() => {
    if (fields.serviceDetail != null) {
      let isWorker =
        workerCenter !== undefined &&
        workerCenter !== '' &&
        workerCenter.split(',').includes(baseModel.pC_Code);
      if (!isWorker) {
        if (fields.serviceDetail.isServiceWorker)
          window.location.href = fields.serviceDetail.serviceRedirectUrl;
      }
    }
  }, []);

  function FnSaveVehicle(parkingPartners, smartParkerCenters, pcCode, visitorId, accessToken, refreshToken) {
    if (isTokenExpired()) {
      var args = [parkingPartners, smartParkerCenters, pcCode];
      GetRenewedAccessToken(SaveVehicle, args);
    } else {
      SaveVehicle(parkingPartners, smartParkerCenters, pcCode, visitorId, accessToken, refreshToken);
    }
  }

  function SaveVehicle(parkingPartners, smartParkerCenters, pcCode, visitorId, accessToken, refreshToken) {
    var numberplate = document.getElementById("licenceplatenumber").value.toString();
    var nickname = document.getElementById("licencevehiclename").value.toString();
    var hasSmartParker = false;
    if (numberplate != null && numberplate != "") {
      if (document.getElementById('loader'))
        document.getElementById('loader').classList.remove('d-none');
      var url = "/api/sitecore/VisitorApi/CreateVehicle";
      if (smartParkerCenters !== "" && smartParkerCenters !== null && smartParkerCenters !== undefined) {
        hasSmartParker = smartParkerCenters.includes(pcCode) ? true : false;
      }
      axios({
        method: "post",
        url: url,
        data: {
          visitorId: visitorId,
          accessToken: accessToken,
          refreshToken: refreshToken,
          licensePlate: numberplate,
          vehicleNickName: nickname,
          pcCode: pcCode,
          hasSmartParker: hasSmartParker,
          parkingPartners: parkingPartners
        },
        async: false,
      })
        .then(function (response) {
          if (response.data[0]) {
            fnGTMSaveVehicle();
            if (!parkingClubActiveUser)
              dispatch(setParkingClubActiveUser(true));
            if (!hasSmartParker) {
              updatePartnerDetail(pcCode, response.data[3])
              SetDefaultValues();
            }
            else {
              SetDefaultValues();
            }
            if (document.getElementById('loader'))
              document.getElementById('loader').classList.add('d-none');
            if (document.getElementById("confirmationview")) {
              document.getElementById("confirmationview").classList.remove('d-none');
            }
            document.getElementById("vehicleinfo").classList.add('d-none');
          }
        })
        .catch(function (error) {
          if (document.getElementById('loader'))
            document.getElementById('loader').classList.add('d-none');
          console.log(error);
        });

      return true;
    }
    else {
      document.getElementById("licenceplatenumber-error").innerHTML = document.getElementById("licenceplatenumber-error").getAttribute("data-validatormsg1").valueOf();
      document.getElementById("licenceplatenumber-error").style.display = "block";
      document.getElementById("licenceplatenumber").classList.add("error");
    }
  }


  if (fields && fields?.serviceDetail != null && fields?.serviceDetail?.serviceInfo !== null) {
    var serviceInfoDescription = { value: fields.serviceDetail.serviceInfo.description }
    var serviceInfolevelName = { value: fields.serviceDetail.serviceInfo.levelName }
    let loyaltyBenefitsDescription = { value: fields.serviceDetail.serviceInfo.loyaltyBenefitsDesc };
    let hasImageBlock = (fields.serviceDetail.serviceInfo.image !== null && fields.serviceDetail.serviceInfo.image !== "" && fields.serviceDetail.display_Picture_For_Service) ? true : false
    return (
      <>
        <section className={"service-detail position-relative py-lg-3 py-0 " + (!hasImageBlock ? (fields.constants.isMobileView ? "" : "d-none") : "")} id="servicedetailImgblock">
          <div className="container my-lg-4 my-0 px-lg-3 p-0">
            <div className={"img-wrap" + (hasImageBlock ? "" : " no-service-image")}>
              {hasImageBlock &&
                <img className="img-fluid w-lg-auto w-100" loading="lazy" src={fields.serviceDetail.serviceInfo.image} alt={fields.serviceDetail.serviceInfo.shortTitle} />
              }
            </div>
          </div>
        </section>
        <section className="service-detail position-relative my-lg-4 my-0 py-lg-3 py-0 bg-2" id="servicedetailPictoblock" >
          <div className="container px-lg-3 p-0">
            <div className="service-detail-mobile-container">
              <div className="row pt-lg-0 pt-4">
                <div className="col-12 col-lg-6 ps-lg-0 pe-lg-3 position-relative">
                  <span className={"picto  " + (fields.serviceDetail.serviceInfo.pictoOn !== "" && fields.serviceDetail.serviceInfo.pictoOn !== null ? fields.serviceDetail.serviceInfo.pictoOn : " icon-servicesaspictosservices") + " d-lg-none d-md-none d-sm-flex align-items-center justify-content-center mb-4 mobile-picto-logo"}></span>
                  <div className="service-picto mb-3 mb-lg-4 d-flex align-items-center justify-content-lg-start justify-content-center">
                    <span className={"picto  " + (fields.serviceDetail.serviceInfo.pictoOn !== "" && fields.serviceDetail.serviceInfo.pictoOn !== null ? fields.serviceDetail.serviceInfo.pictoOn : " icon-servicesaspictosservices") + " me-3 d-none"}></span>
                    <h1 className="page-title text-uppercase m-0 p-0">{fields.serviceDetail.serviceInfo.shortTitle}</h1>
                  </div>
                  <div className="description mb-2 text-start">
                    <div className="content ">
                      <RichText field={serviceInfoDescription} />
                    </div>
                  </div>

                  {fields.constants.isMobileView &&
                    adressAndOpeningHoursBlock(fields)
                  }
                  <EasyParking fields={fields} visitorId={visitorId} />
                  <div className="row mb-3">
                    <div className="col-12 col-lg-6 text-start p-lg-0 service-contact">
                      {(fields.serviceDetail.serviceInfo.levelName !== "" && fields.serviceDetail.serviceInfo.levelName !== null) &&
                        <div className="label mb-3 d-flex">
                          <span className="picto icon-location"></span>
                          <div className="ms-3 flex-grow-1 service-contact-desscription">
                            <div className="text text-uppercase fw-bold">Localisation</div>
                            <div className="text"><RichText field={serviceInfolevelName} /></div>
                          </div>
                        </div>
                      }
                      {(fields.serviceDetail.serviceInfo.phoneNumber !== "" && fields.serviceDetail.serviceInfo.phoneNumber !== null || fields.serviceDetail.serviceInfo.email !== "" && fields.serviceDetail.serviceInfo.email !== null) &&
                        <div className="label mb-32 mb-lg-0 d-flex">
                          <span className="picto icon-infos"></span>
                          <div className="ms-3 flex-grow-1 service-contact-desscription">
                            <div className="text text-uppercase fw-bold">{fields.serviceDetail.contactText}</div>
                            <div className="text">
                              {fields.serviceDetail.serviceInfo.phoneNumber} <br />
                              {fields.serviceDetail.serviceInfo.email}
                            </div>
                          </div>
                        </div>
                      }
                    </div>

                  </div>                
                </div>
                <div className="col-12 col-lg-6 ps-lg-3 pe-lg-0">
                  {fnNonEuropeanRegionData(fields)}
                </div>
              </div>
            </div>
          </div>
        </section>
        {fields.serviceDetail.lightSmartParkingData !== null && fields.serviceDetail.lightSmartParkingData !== undefined && fields.serviceDetail.lightSmartParkingData !== "" &&
          <><div className="service-detail bg-gray py-4 position-relative d-none" id="vehicleinfo">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-12 licence-container">
                  <h2 className="licence-plate-header">{fields.serviceDetail.lightSmartParkingData.licensePlateTitle}
                  </h2>
                  <div className="mb-3 licence-plate-description">
                    {fields.serviceDetail.lightSmartParkingData.licensePlateDesc}
                  </div>
                  <div className="row licence-plate-input">
                    <div className="col-md-12 col-sm-12 col-12 p-0">
                      <div className="form-group mb-1 position-relative">
                        <label htmlFor="licenceplatenumber" className="profile-label">{fields.serviceDetail.lightSmartParkingData.licensePlateNumber}</label>
                        <span className="picto icon-closed-circle-rw" onClick={() => closeIconClick()}></span>
                        <input className="form-control mt-1" type="text" name="licenceplatenumber" id="licenceplatenumber" required=""
                          data-testid="setBtnProfileSubmit5" placeholder={fields.serviceDetail.lightSmartParkingData.licensePlatePlaceholder} onBlur={(e) => { NumberplateValidation(); }} />
                        <span id="licenceplatenumber-error" className="error" data-validatormsg1={fields.serviceDetail.lightSmartParkingData.licensePlateValidationErr} data-validatormsg2="License plate number"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row licence-plate-input">
                    <div className="col-md-12 col-sm-12 col-12 p-0">
                      <div className="form-group mb-1 position-relative ">
                        <label htmlFor="licencevehiclename" className="profile-label">{fields.serviceDetail.lightSmartParkingData.vehicleName}</label>
                        <input className="form-control mt-1" type="text" name="licencevehiclename"
                          id="licencevehiclename" data-testid="setBtnProfileSubmit5" placeholder={fields.serviceDetail.lightSmartParkingData.vehiclePlaceholder} />
                      </div>
                    </div>
                  </div>
                  <div className=" mt-3 align-items-center d-flex m-auto justify-content-start">
                    <a href="javascript:void(0);" aria-label="Découvrir mes avantages" className="btn-loyalty-card btn btn-dark loyalty-card-btn-discover-benefits"
                      onClick={() => FnSaveVehicle(parkingPartners, smartParkerCenters, fields.serviceDetail.lightSmartParkingData.pcCode, visitorId, accessToken, refreshToken)} >{fields.serviceDetail.lightSmartParkingData.addVehicleCTA}</a></div>
                </div>
              </div>
            </div>
            <div id="loader" data-bs-title="dot-spin" className="d-flex align-items-center justify-content-center account-loader d-none bg-black opacity-50">
              <div className="dot-spin"></div>
            </div>
          </div>
            <div className="service-detail bg-gray py-4 position-relative d-none" id="confirmationview">
              <div className="container">
                <div className="row licence-congrats-container ">
                  <div className="col-lg-6 col-md-12 order-lg-0 order-md-0 order-sm-1 order-1">
                    <h2 className="mt-0 licence-congrats-header d-lg-flex d-md-flex d-none">{fields.serviceDetail.lightSmartParkingData.confirmationTitle}</h2>
                    <div className="mb-4 licence-congrats-description d-lg-flex d-md-flex d-none">
                      {fields.serviceDetail.lightSmartParkingData.confirmationDesc}
                    </div>
                    <div className="mb-4 licence-mobile-description d-lg-none d-md-none d-sm-flex">
                      {fields.serviceDetail.lightSmartParkingData.confirmationTitle}{fields.serviceDetail.lightSmartParkingData.confirmationDesc}
                    </div>
                    <div className="description text-start text-midgray mb-4">
                      <RichText field={fields.serviceDetail.lightSmartParkingData.accountLinkText} />
                    </div>
                    <footer>
                      <div className="licence-congrats-prefooter">
                        {fields.serviceDetail.lightSmartParkingData.confirmationDesc}
                      </div>
                      <div className=" mt-3 align-items-center d-flex m-auto justify-content-start">
                        <a href="javascript:void(0);" aria-label="Découvrir mes avantages" className="btn-loyalty-card btn btn-dark loyalty-card-btn-discover-benefits"
                          onClick={() => btnConfirmationClick()}>{fields.serviceDetail.lightSmartParkingData.confirmationCTAText}</a>
                      </div>
                    </footer>
                  </div>
                  <div className="col-lg-6 col-md-12 order-lg-1 order-md-1 order-sm-0 order-0">
                    <div className="img-wrap d-flex justify-content-center mb-4">
                      {fields.serviceDetail.isNewBranded ?
                        (<><img src={fields.serviceDetail.lightSmartParkingData.confirmationBrandedImg?.value?.src} alt={fields.serviceDetail.lightSmartParkingData.confirmationBrandedImg?.value?.alt} className="border-8 d-lg-flex d-md-flex  justify-content-center d-none img-fluid" />
                          <img src={fields.serviceDetail.lightSmartParkingData.confirmationMobileBrandedImg?.value?.src} alt={fields.serviceDetail.lightSmartParkingData.confirmationMobileBrandedImg?.value?.src} className="border-8 d-lg-none d-md-none d-sm-flex justify-content-center img-fluid" />
                        </>
                        ) : (
                          <>
                            <img src={fields.serviceDetail.lightSmartParkingData.confirmationUnbrandedImg?.value?.src} alt={fields.serviceDetail.lightSmartParkingData.confirmationUnbrandedImg?.value?.src} className="border-8 d-lg-flex d-md-flex justify-content-center d-none img-fluid" />
                            <img src={fields.serviceDetail.lightSmartParkingData.confirmationMobileUnbrandedImg?.value?.src} alt={fields.serviceDetail.lightSmartParkingData.confirmationMobileUnbrandedImg?.value?.src} className="border-8 d-lg-none d-md-none d-sm-flex justify-content-center img-fluid" />
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </>
    )
  } else {
    return <></>;
  }
}


function fnNonEuropeanRegionData(fields) {
  if (fields.serviceDetail.isParkingService) {
    return (
      <>
        {!fields.constants.isMobileView &&
          <>
            <GoogleMapsCenterTest fields={fields} isServiceDetailPage={true} />
            {adressAndOpeningHoursBlock(fields)}
          </>
        }
      </>
    )
  }
  else if (!fields.serviceDetail.isLoyaltyProgramDisabled && fields.serviceDetail.isEuropeanRegion) {
    return (
      (fields.serviceDetail.showServicePillar) ?
        (
          <><ServicePillar model={fields.serviceDetail.servicePillar} constants={fields.constants} isClubPage={fields.serviceDetail.isClubPage} />
            {!fields.constants.isMobileView &&
              adressAndOpeningHoursBlock(fields)}
          </>
        )
        :
        (
          <>
            <HowToEnjoyIt model={fields.serviceDetail.howToEnjoyIt} fields={fields} />
            {!fields.constants.isMobileView &&
              adressAndOpeningHoursBlock(fields)
            }
          </>
        )
    )
  }
}

function adressAndOpeningHoursBlock(fields) {
  var serviceInfoPriceText = { value: fields.serviceDetail.priceText }
  var serviceInfoprice = { value: fields.serviceDetail.serviceInfo.price }

  return (
    <div className="service-detail-accordian">
      <div className="accordion accordion-flush" id="accordionFlushOne">
        {fields.serviceDetail.isParkingService &&
          <>
            <div className="accordion-item mb-3 border-0">
              <div className="accordion-header">
                <button className="bg-gray shadow-none border-0 p-3 w-100 d-flex flex-row align-items-center detail-accordian-address-container cursor-default" type="button" aria-expanded="false">
                  <span className="picto  icon-location-rw me-3 d-flex flex-column"></span>
                  <div className="d-flex flex-column">
                    <div className="d-flex  flex-start detail-accordian-address text-start">{fields.serviceDetail.centerAddress}</div>
                  </div>
                </button>
              </div>
            </div>
            <div className="accordion-item mb-3 border-0">
              <div className="accordion-header" id="flush-headingOne">
                <div className="bg-gray accordion-button shadow-none border-0 p-3 w-100 d-flex flex-row  detail-accordian-address-container align-items-start collapsed cursor-pointer"
                  onClick={(e) => {
                    showMore(e);
                  }}>
                  <span className="picto icon-clock-rw me-3 d-flex flex-start mt-1"></span>
                  <div className="d-flex flex-row flex-start w-100">
                    <ul className="ms-0 mb-0 p-0 bg-gray list-unstyled">
                      {fields.serviceDetail.opening.map((item, index) => {
                        return (
                          <li key={index} className={(item.isToDay ? "d-flex active" : "d-none") + " justify-content-between  time-text-area"}>
                            <span className="days fw-bold">{item.day}</span>
                            <span className="hours ps-2 ">
                              {item.openingInfo}
                            </span>
                          </li>
                        )
                      })}

                    </ul>
                  </div>
                </div>

              </div>

            </div>
          </>
        }
        {serviceInfoprice.value !== "" && serviceInfoprice.value !== null && serviceInfoprice.value !== undefined &&
          <div className="accordion-item mb-3 border-0">
            <div className="accordion-header" id="flush-headingTwo">
              <div className="accordion-button bg-gray shadow-none cursor-pointer collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                <span className="picto  icon-money-rw me-3"></span>
                <span className="fw-bold detail-accordian-day"><RichText field={serviceInfoPriceText} /> </span>
              </div>
            </div>
            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushOne" >
              <div className="accordion-body bg-gray">
                <div className="tarif">
                  <RichText field={serviceInfoprice} />
                </div>

              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
function showMore(e) {
  e.preventDefault();
  e.stopPropagation();

  if (e.currentTarget.classList.contains("collapsed")) {
    e.currentTarget.classList.remove("collapsed");
    let ele = document.getElementsByClassName("time-text-area");
    for (let i = 0; i < ele.length; i++) {
      ele[i].classList.remove("d-none");
      ele[i].classList.add("d-flex");
    }

  } else {
    e.currentTarget.classList.add("collapsed")
    let ele = document.getElementsByClassName("time-text-area");
    for (let i = 0; i < ele.length; i++) {
      if (!ele[i].classList.contains("active")) {
        ele[i].classList.add("d-none");
        ele[i].classList.remove("d-flex");
      }
    }
  }
}

function NumberplateValidation() {
  var numberplate = document.getElementById("licenceplatenumber").value.trim();
  if (numberplate === "") {
    document.getElementById("licenceplatenumber-error").innerHTML = document.getElementById("licenceplatenumber-error").getAttribute("data-validatormsg1").valueOf();
    document.getElementById("licenceplatenumber-error").style.display = "block";
    document.getElementById("licenceplatenumber").classList.add("error");
    return false;
  }
  else {
    document.getElementById("licenceplatenumber-error").innerHTML = "";
    document.getElementById("licenceplatenumber").classList.remove("error");
    return true;
  }
}
function closeIconClick() {
  document.getElementById("licenceplatenumber").value = "";
}


function SetDefaultValues() {
  if (document.getElementById("licenceplatenumber-error")) { document.getElementById("licenceplatenumber-error").innerHTML = ""; }
  if (document.getElementById("licenceplatenumber") && document.getElementById("licenceplatenumber").classList.contains("error")) { document.getElementById("numberplate").classList.remove("error"); }
  if (document.getElementById("licenceplatenumber")) { document.getElementById("licenceplatenumber").value = ""; }
  if (document.getElementById("licencevehiclename")) { document.getElementById("licencevehiclename").value = ""; }
}
function btnConfirmationClick() {
  if (document.getElementById("servicedetailImgblock")) {
    document.getElementById("servicedetailImgblock").classList.remove('d-none')
  }
  if (document.getElementById("servicedetailPictoblock")) {
    document.getElementById("servicedetailPictoblock").classList.remove('d-none')
  }
  if (document.getElementById('vehicleinfo')) {
    document.getElementById('vehicleinfo').classList.add('d-none')
  }
  if (document.getElementById("confirmationview")) {
    document.getElementById("confirmationview").classList.add('d-none');
  }
}
