import React, { useState, useEffect } from 'react';
import MyAccountBasicBenefits from '../MyAccountBasicBenefits';
import { GoBackToMenu } from "../MyAccount/MyAccountScripts";
import SelectShoppingCenter from '../SelectShoppingCenter';
import ReactDOM from "react-dom";
import { HideBenefitsAndServicesTab, createCookie, isTokenExpired, GetRenewedAccessToken } from '../Js-CommonFunctions';
import axios from 'axios';
import SmartParkingServices from '../SmartParkingServices';
import ParkingConfirmationPopin from "../ParkingConfirmationPopin";
import LPDiscoverMyAdvantages from '../LPDiscoverMyAdvantages';
import OpinionBlock from '../OpinionBlock';
import BenefitsHeaderTab from '../BenefitsHeaderTab';
import { baseModel } from '../../RouteHandler';
import { sortCenterList, isMobile } from '../Js-CommonFunctions';
import { useSelector } from 'react-redux';


export default function MyBenefitsAndServices({ fields, setBenefitsTab, preferredCenters, setPreferredCenters, isUpdateCenter, setIsUpdateCenter, legalTextIdInfo, setLegalTextIdInfo }) {

    const [model, setModel] = useState();
    // const [opinionData, setOpinionData] = useState();
    var dataItem = null;
    var dataIndex = null;
    var dataModel = null;
    var monoScItem = null;
    const [showPopup, setshowPopup] = useState(true);
    var visitorId = useSelector((state) => state.user.visitorId)
    var accessToken = useSelector((state) => state.user.accessToken)
    var refreshToken = useSelector((state) => state.user.refreshToken)
    var acquisitionPcCode = useSelector((state) => state.user.acquisitionPcCode);
    var iLogsSmartParker = useSelector((state) => state.user.smartParkUser);

    var visitorInfo = {
        visitorId: visitorId,
        accessToken: accessToken,
        refreshToken: refreshToken
    }
    var queryStringShowSuccessPopin = "";
    var queryStringPcCode = "";
    if (typeof window !== "undefined") {
        const query = new URLSearchParams(window.location.search);
        queryStringShowSuccessPopin = query.get('ShowSuccessPopin');
        queryStringPcCode = query.get('pcCode');
    }
    useEffect(() => {
        if (fields !== undefined && fields !== null && fields !== "") {
            if (fields.list !== undefined && fields.list !== null && fields.list.length > 0) {
                fields.list = [...fields.list].sort((a, b) => a.centerLabel > b.centerLabel ? 1 : -1)
                fields.list = sortCenterList(fields.list);
            }
            setModel(fields);

        }
    }, []);

    useEffect(() => {
        if (isUpdateCenter) {
            getBenefits();
        }
        if (dataItem !== null && dataIndex !== null && dataModel !== null) {
            displayBenefitsAndServicesSecondaryparking(dataItem, dataIndex, dataModel)
        }
        if (monoScItem !== null && monoScItem !== "") {
            renderSecondaryPopin(monoScItem.easyParking.secondaryParkingBlock);
        }
    }, [preferredCenters, dataItem, monoScItem]);

    function getBenefits() {
        axios({
            method: "post",
            url:
                "/api/sitecore/VisitorApi/GetBenefitsAndServices?ursite=" +
                baseModel.urContextSite +
                "&urlang=" +
                baseModel.contextLang +
                "&serviceBrandedDatasource=" +
                fields.serviceBrandedDatasource +
                "&serviceUnBrandedDatasource=" +
                fields.serviceUnBrandedDatasource +             
                "&opinionBlockDatasource=" +
                fields.opinionBlock.opinionBlockDatasource,
            data: {
                visitorId: visitorId,
                accessToken: accessToken,
                refreshToken: refreshToken,
                acquisitionPcCode: acquisitionPcCode
            },
            async: false,
        })
            .then(function (response) {
                if (response.data.list != undefined && response.data.list.length > 0) {
                    response.data.list = [...response.data.list].sort((a, b) => a.centerLabel > b.centerLabel ? 1 : -1)
                    response.data.list = sortCenterList(response.data.list)
                }
                setModel(response.data);
                setBenefitsTab(response.data.hideBenefitsAndServices);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function displayBenefitsAndServicesMultiSC(item, index, fields) {
        if (document.getElementById("benefitsServices") && document.getElementById("my-benefits-and-services")) {

            document.getElementById("benefitsServices").classList.add('d-none');
            if (document.getElementById('lpdiscovermyadvantagesmultisc') !== null && document.getElementById('lpdiscovermyadvantagesmultisc') !== undefined && document.getElementById('lpdiscovermyadvantagesmultisc') !== "") {
                document.getElementById('lpdiscovermyadvantagesmultisc').classList.add('d-none');
            }
            if (document.getElementsByClassName('multi-preferred-shopping-center')[0]) {
                //Tab ele
                var tabEle = document.createElement("DIV");
                tabEle.setAttribute("id", "multisctab");
                document.getElementsByClassName('multi-preferred-shopping-center')[0].appendChild(tabEle);

                var multipreferredscEle = document.createElement("DIV");
                multipreferredscEle.setAttribute("id", "multipreferredsc");
                document.getElementsByClassName('multi-preferred-shopping-center')[0].appendChild(multipreferredscEle);

                var smartParkingEle = document.createElement("DIV");
                smartParkingEle.setAttribute("id", "smartparkingview")
                document.getElementsByClassName('multi-preferred-shopping-center')[0].appendChild(smartParkingEle);

                var opinionBlockEle = document.createElement("DIV");
                opinionBlockEle.setAttribute("id", "opinionblock");
                document.getElementsByClassName('multi-preferred-shopping-center')[0].appendChild(opinionBlockEle);

                if (document.getElementById('opinionblock') && document.getElementById('opinionblock').classList.contains('d-none')) {
                    document.getElementById('opinionblock').classList.remove('d-none');
                }

                var loyaltyAdvEle = document.createElement("DIV");
                loyaltyAdvEle.setAttribute("id", "loyaltydiscovermyadvantages");
                document.getElementsByClassName('multi-preferred-shopping-center')[0].appendChild(loyaltyAdvEle);

                displayBenefitsAndServices(item, index, model, true);

            }
        }
    }

    function displayBenefitsAndServicesMonoSC(item, index, fields) {
        if (document.getElementById("my-benefits-and-services")) {
            if (!document.getElementById('hiddenElement')) {
                var hiddenElement = document.createElement("INPUT");
                hiddenElement.setAttribute("id", "hiddenElement");
                hiddenElement.setAttribute("type", "hidden");
                hiddenElement.setAttribute("value", item.pcCode);
                document.getElementById('my-benefits-and-services').appendChild(hiddenElement);
            }
            else {
                document.getElementById('hiddenElement').setAttribute("value", item.pcCode);

                let contentEle = document.getElementById("accountMain");
                if (contentEle !== null && contentEle !== undefined && contentEle !== "" &&
                    contentEle.classList !== null && contentEle.classList !== undefined && contentEle.classList !== "") {

                    if (item.isWestfield) {
                        if ((contentEle.classList.contains("account-brandedpage-loyalty"))) {
                            contentEle.classList.remove("account-brandedpage-loyalty");
                        }
                        contentEle.classList.add("account-unbrandedpage-loyalty")
                    }

                    else {
                        if ((contentEle.classList.contains("account-unbrandedpage-loyalty"))) {
                            contentEle.classList.remove("account-unbrandedpage-loyalty");
                        }
                        contentEle.classList.add("account-brandedpage-loyalty")
                    }
                }
            }
            displayBenefitsAndServices(item, index, model, false);
        }
    }

    function goodaysScript(item, brandedKey, unbrandedKey) {
        if (document.getElementById("goodaysopinion")) {
            document.body.removeChild(document.getElementById("goodaysopinion"));
        }

        var script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "goodaysopinion";
        script.innerHTML =
            `(function(i,s,o,g,r,a,m) {i[r]=i[r],a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.src=g;m.parentNode.insertBefore(a,m)
                })(window,document,'script','https://cdn.goodays.co/sdk/`+ (item.isWestfield ? brandedKey : unbrandedKey) + `.js','cz');
                GoodaysSDK={};
                GoodaysSDK.config={
                "lang": "`+ item.centerLang + `"
              };`;

        document.body.appendChild(script);
    }

    function displayBenefitsAndServices(item, index, model, multiSC) {
        goodaysScript(item, model.goodaysKeyBranded, model.goodaysKeyUnbranded);
        if (item.hasLoyaltyBenefits) {
            return (
                ((item.hasSmartParkerAvailable || item.hasSmartParkerActivated || (iLogsSmartParker && item.displayILogsParking))) ?
                    (
                        (multiSC ?
                            (
                                <>
                                    {document.getElementById('loader') && document.getElementById('loader').classList.remove('d-none')}
                                    {ReactDOM.render(<BenefitsHeaderTab fields={model} label={item.centerLabel} isMultiCentre={multiSC} isEasyParking={item.displayEasyParking} />, document.getElementById("multisctab"))}
                                    {FngetBasicBenefits(item.pcCode, multiSC, item, model, false)}
                                    {FngetSmarkingServices(item.pcCode, item, multiSC, false)}
                                    {(model.discoverMore !== undefined && model.discoverMore !== null && model.discoverMore !== "") &&
                                        ReactDOM.render(<LPDiscoverMyAdvantages fields={model.discoverMore} />, document.getElementById("loyaltydiscovermyadvantages"))
                                    }
                                </>
                            ) :
                            (<>
                                {document.getElementById('loader') && document.getElementById('loader').classList.remove('d-none')}
                                {FngetBasicBenefits(item.pcCode, false, item, model, false, true, true)}
                                {FngetSmarkingServices(item.pcCode, item, false, false)}
                            </>
                            ))

                    ) :
                    (
                        (multiSC ?
                            (
                                <>
                                    {document.getElementById('loader') && document.getElementById('loader').classList.remove('d-none')}
                                    {FngetBasicBenefits(item.pcCode, multiSC, item, model, true)}
                                    {(model.discoverMore !== undefined && model.discoverMore !== null && model.discoverMore !== "") &&
                                        ReactDOM.render(<LPDiscoverMyAdvantages fields={model.discoverMore} />, document.getElementById("loyaltydiscovermyadvantages"))
                                    }
                                </>
                            ) :
                            (
                                <>
                                    {document.getElementById('loader') && document.getElementById('loader').classList.remove('d-none')}
                                    {FngetBasicBenefits(item.pcCode, false, item, model, true, true)}
                                </>

                            )
                        )
                    )
            )
        }
        else if (item.hasSmartParkerAvailable || item.hasSmartParkerActivated || (iLogsSmartParker && item.displayILogsParking)) {
            return (
                multiSC ?
                    (
                        <>
                            {document.getElementById('loader') && document.getElementById('loader').classList.remove('d-none')}
                            {FngetSmarkingServices(item.pcCode, item, multiSC, true)}

                        </>

                    ) :
                    (
                        <SmartParkingServices fields={item} isMultiCentre={multiSC} displayOnlyParking={true} visitorInfo={visitorInfo} iLogsSmartParker={iLogsSmartParker}/>
                    )
            )
        }
        else {
            if (item.centerRegion !== "US" && item.hasFavorite) {
                return (
                    multiSC ?
                        (
                            AddPreferredSC(model.addpreferredSCModel)
                        ) :
                        (
                            AddPreferredSC(model.addpreferredSCModel)
                        )
                )
            }
            else {
                return (
                    HideBenefitsAndServicesTab()
                )
            }
        }
    }
    function AddPreferredSC(fields) {
        if (fields !== undefined && fields !== null && fields !== "" && fields.discoverModel !== undefined && fields.discoverModel !== null && fields.discoverModel !== "") {
            return (
                <div id="addpreferredscview">
                    <div className="tab-pane preferred-sc fade show active p-0 border-0 addpreferredsc" id="add-preferred-sc"
                        role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div className="account-tab-pane1  border-8 bg-white ">
                            <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
                                <div className="container modalContainer p-0 ">
                                    <nav className="navbar benefits-header-height px-lg-1 row m-0   py-2">
                                        <div className="col-2 text-center px-0">
                                            <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                                                href="javascript:void(0);" data-testid="test1" onClick={() => { GoBackToMenu("my-benefits-and-services") }}></a>
                                        </div>
                                        <div className="col-8 p-0 text-center">
                                            <div className=" text-center benefits-subtitle ">{fields.title}</div>
                                        </div>
                                        <div className="col-2 text-center px-0"></div>
                                    </nav>
                                </div>
                            </div>
                            <div
                                className="d-flex d-lg-block d-none d-xl-block justify-content-start  account-center-name">{fields.title} </div>

                            <div className="d-flex my-3 preferred-sc-card-header mob-pd text-black ps-1">
                                <span className="picto icon-vip-card account-icon-spacing  fs-3"></span>
                                <span className="d-flex ms-2 account-card-header align-items-center">{fields.loyaltyProgramTitle}</span>
                            </div>

                            <div className="col-12 preferred-sc-box bg-white text-center">
                                <div className="preferred-sc-title mt-3">{fields.discoverModel?.discoverMoreTitle}</div>
                                <div className="preferred-sc-desc my-3"> {fields.discoverModel?.discoverMoreDescription}</div>
                                <div className="align-items-center d-flex justify-content-center ">
                                    {fields.discoverModel?.link?.value?.url?.includes("javascript") ?
                                        (
                                            <a href="javascript:void(0);" aria-label={fields?.discoverModel?.link?.value} data-testid="test2" onClick={(e) => { fnSelectPreferredSC(fields) }} className="btn btn-preferred-sc btn-dark my-3 align-items-center">{fields.discoverModel?.link?.value?.text}</a>
                                        ) :
                                        (
                                            (fields.discoverModel.link?.value?.url !== null && fields.discoverModel.link?.value?.url !== undefined && fields.discoverModel.link?.value?.url !== "") ?
                                                (<a href={fields.discoverModel.link?.value?.url} aria-label={fields?.discoverModel?.link?.value} className="btn btn-preferred-sc btn-dark my-3 align-items-center">{fields.discoverModel?.link?.value?.text}</a>)
                                                : (
                                                    <a href="javascript:void(0);" aria-label={fields?.discoverModel?.link?.value} data-testid="test3" onClick={(e) => { fnSelectPreferredSC(fields) }} className="btn btn-preferred-sc btn-dark my-3 align-items-center">{fields.discoverModel?.link?.value?.text}</a>
                                                )
                                        )}
                                </div>
                            </div>

                            <a className="d-flex justify-content-center preferred-sc-discover-button account-discover-button" href={"/" + fields.loyaltyProgramPageLink}>{fields.discoverMyAdv}</a>
                        </div>

                    </div>
                </div>
            )
        }
        else {
            return <></>
        }
    }
    function fnSelectPreferredSC(data) {
        var visitorInfo = {
            visitorId: visitorId,
            accessToken: accessToken,
            refreshToken: refreshToken
        }
        if (data !== null && data !== undefined && data !== "") {
            if (data.isMobileView) {
                if (document.getElementById('addpreferredscview')) {
                    document.getElementById('add-preferred-sc').classList.add('d-none');
                    var shoppingcenterEle = document.createElement("DIV");
                    shoppingcenterEle.setAttribute("id", "selectshoppingcenterview");
                    document.getElementById('addpreferredscview').appendChild(shoppingcenterEle);
                    ReactDOM.render(<SelectShoppingCenter fields={data} consent={data.consentPopinData} preferredCenters={preferredCenters} newBenefitsAndServices={newBenefitsAndServices} updateLegalTextId={updateLegalTextId} legalTextIdInfo={legalTextIdInfo} visitorInfo={visitorInfo} />, document.getElementById("selectshoppingcenterview"));
                }
            }
            else {
                ReactDOM.render(<SelectShoppingCenter fields={data} consent={data.consentPopinData} preferredCenters={preferredCenters} newBenefitsAndServices={newBenefitsAndServices} updateLegalTextId={updateLegalTextId} legalTextIdInfo={legalTextIdInfo} visitorInfo={visitorInfo} />, document.getElementById("popinPreferredSC"));
                // Triggers the Popin
                var popinClick = document.getElementById("popinPreferredSCClick");
                popinClick.click();
            }
        }
    }

    function FngetSmarkingServices(pcCode, item, multiSC, displayOnlyParking) {
        if (isTokenExpired()) {
            var args = [pcCode, item, multiSC, displayOnlyParking];
            GetRenewedAccessToken(getSmarkingServices, args);
        } else {
            getSmarkingServices(pcCode, item, multiSC, displayOnlyParking, visitorId, accessToken, refreshToken);
        }
    }

    function getSmarkingServices(pcCode, item, multiSC, displayOnlyParking, visitorId, accessToken, refreshToken) {
        var parkingServiceType = "";
        var hasSmartParkerActivated = item.hasSmartParkerActivated;
        if (item.displayOtherParking)
            parkingServiceType = model.otherParkingServiceConst;
        if (item.displayILogsParking)
            {
                parkingServiceType = model.iLogsParkingServiceConst;
                hasSmartParkerActivated = iLogsSmartParker;
            }
        
        axios({
            method: "post",
            url:
                "/api/sitecore/VisitorApi/GetParkingServiesForMultiSC?pcCode=" +
                pcCode +
                "&SmartParkingServiceType=" +
                parkingServiceType +
                "&center=" +
                item.centerLabel +
                "&contextLanguage=" +
                baseModel.contextLang +              
                "&accountUrl=" +
                item.accountUrl,
              
            data: {
                visitorId: visitorId,
                accessToken: accessToken,
                refreshToken: refreshToken,
            },
            async: false,
        })
            .then(function (response) {
                if (response !== null && response !== undefined && response !== "" && response.data !== null && response.data !== undefined && response.data !== "") {
                    var parkingdata = response.data.benifitsAndServices;
                   
                    if (queryStringShowSuccessPopin === "easy-parking" && showPopup) {
                        renderSecondaryPopin(response.data.benifitsAndServices.easyParking.secondaryParkingBlock);
                    }
                    if (parkingdata !== null && parkingdata !== undefined && parkingdata !== "") {

                        if (!multiSC) {
                            ReactDOM.render(<SmartParkingServices fields={parkingdata} isMultiCentre={multiSC} displayOnlyParking={displayOnlyParking} visitorInfo={visitorInfo} iLogsSmartParker={iLogsSmartParker} />, document.getElementById("parkingmonosc"));
                        }
                        else {
                            ReactDOM.render(<SmartParkingServices fields={parkingdata} isMultiCentre={multiSC} displayOnlyParking={displayOnlyParking} visitorInfo={visitorInfo} iLogsSmartParker={iLogsSmartParker} />, document.getElementById("smartparkingview"));
                        }
                        if (document.getElementById('loader'))
                            document.getElementById('loader').classList.add('d-none')
                    }
                }
                else {
                    if (document.getElementById('loader'))
                        document.getElementById('loader').classList.add('d-none')
                }
            })
            .catch(function (error) {
                console.log(error);
                if (document.getElementById('loader'))
                    document.getElementById('loader').classList.add('d-none')
            });

    }

    function renderSecondaryPopin(secondaryParkingData) {
        var smartparking = document.getElementById('my-parking-tab');
        if (smartparking !== null && smartparking !== undefined && smartparking !== "" && isMobile.any()) {
            setTimeout(function () {
                smartparking.click();
            }, 3000);

        }
        ReactDOM.render(<ParkingConfirmationPopin fields={secondaryParkingData} />, document.getElementById("popinParkingConfirmation"));
        // Triggers the Popin
        var popinClick = document.getElementById("popinParkingConfirmationClick");
        popinClick.click();
        setTimeout(function () { displaySecondaryPopin(); }, 1000);
        setshowPopup(false);
       
    }


    function displaySecondaryPopin() {
        var popinmodel = document.getElementById("ParkingConfirmationPopin");
        var popin = document.getElementById("popinParkingConfirmation");
        if (popinmodel !== null && popinmodel !== undefined && popinmodel !== "" &&
            popin !== null && popin !== undefined && popin !== "") {
            popinmodel.style = "display: block; padding-left: 0px;"
            popin.style = "display: block; padding-left: 0px;"
        }
    }

    function displayBenefitsAndServicesSecondaryparking(item, index, model) {
        displayBenefitsAndServicesMultiSC(item, index, model, true);
    }

    function FngetBasicBenefits(pcCode, multiSC, item, model, displayOnlyBasicBenefits, renderLpAdvantages, bindBenefitsHeader)
    {
        if (isTokenExpired()) {
            var args = [pcCode, multiSC, item, model, displayOnlyBasicBenefits, renderLpAdvantages, bindBenefitsHeader];
            GetRenewedAccessToken(getBasicBenefits, args);
        } else {
            getBasicBenefits(pcCode, multiSC, item, model, displayOnlyBasicBenefits, renderLpAdvantages, bindBenefitsHeader, visitorId, accessToken, refreshToken);
        }
    }

    function getBasicBenefits(pcCode, multiSC, item, model, displayOnlyBasicBenefits, renderLpAdvantages, bindBenefitsHeader, visitorId, accessToken, refreshToken) {
        axios({
            method: "post",
            url:
                "/api/sitecore/VisitorApi/GetBasicBenefitsForMultiSC?pcCode=" +
                pcCode +
                "&center=" +
                item.centerLabel +
                "&hasLoyalty=" +
                item.hasLoyalty +
                "&contextLanguage=" +
                baseModel.contextLang +
                "&servicesDatasource=" +
                item.servicesDatasource +
                "&opinionBlockDatasource=" +
                model.opinionBlock.opinionBlockDatasource,
            data: {
                visitorId: visitorId,
                accessToken: accessToken,
                refreshToken: refreshToken,
            },
            async: false,
        })
            .then(function (response) {
                if (response !== null && response !== undefined && response !== "" && response.data !== null && response.data !== undefined && response.data !== "") {

                    if (!multiSC) {
                        ReactDOM.render(<MyAccountBasicBenefits item={response.data.benifitsAndServices} fields={model} isMultiCentre={multiSC} displayOnlyBasicBenefits={displayOnlyBasicBenefits} isWestfield={item.isWestfield} />, document.getElementById("benefitsmonosc"))
                        if (model.opinionBlock.opinionBlockDatasource !== undefined && model.opinionBlock.opinionBlockDatasource !== null &&
                            model.opinionBlock.opinionBlockDatasource !== "") {
                            ReactDOM.render(<OpinionBlock fields={response.data.opinionBlock} isWestfield={item.isWestfield} />, document.getElementById("opinionmonosc"))
                        }
                        if (bindBenefitsHeader) {
                            { ReactDOM.render(<BenefitsHeaderTab fields={model} label={item.centerLabel} isMultiCentre={multiSC} isEasyParking={item.displayEasyParking} />, document.getElementById("benefitsheadermonosc")) }
                        }
                        if (renderLpAdvantages) {
                            {
                                (model.discoverMore !== undefined && model.discoverMore !== null && model.discoverMore !== "") &&
                                    ReactDOM.render(<LPDiscoverMyAdvantages fields={model.discoverMore} />, document.getElementById("loyaltyadvantagesmonosc"))
                            }
                        }
                    }
                    else {
                        ReactDOM.render(<MyAccountBasicBenefits item={response.data.benifitsAndServices} fields={model} isMultiCentre={multiSC} displayOnlyBasicBenefits={displayOnlyBasicBenefits} isWestfield={item.isWestfield} />, document.getElementById("multipreferredsc"))
                        if (model.opinionBlock.opinionBlockDatasource !== undefined && model.opinionBlock.opinionBlockDatasource !== null &&
                            model.opinionBlock.opinionBlockDatasource !== "") {
                            ReactDOM.render(<OpinionBlock fields={response.data.opinionBlock} isWestfield={item.isWestfield} />, document.getElementById("opinionblock"))
                        }
                    }
                    if (document.getElementById('loader') && displayOnlyBasicBenefits)
                        document.getElementById('loader').classList.add('d-none')
                } else {
                    if (document.getElementById('loader') && displayOnlyBasicBenefits)
                        document.getElementById('loader').classList.add('d-none')
                }
            })
            .catch(function (error) {
                console.log(error);
                if (document.getElementById('loader') && displayOnlyBasicBenefits)
                    document.getElementById('loader').classList.add('d-none')
            });

    }
    function newBenefitsAndServices(centerName, pcCode, countryCode) {
        setIsUpdateCenter(true);
        setPreferredCenters([
            ...preferredCenters,
            { centerName: centerName, pcCode: pcCode, countryCode: countryCode }
        ]);

        createCookie("RefreshContent", "personalinformations-communications-loyaltycard", 0);
    }
    function updateLegalTextId(legalTextId) {
        setLegalTextIdInfo(({ }) => ({
            ...legalTextIdInfo,
            visitorOptedLegalTextId: legalTextId,
        }));
    }

    if (model !== undefined && model !== null && model !== "") {
        if (model.addPreferedSC) {
            return (
                AddPreferredSC(model.addpreferredSCModel)
            )
        }
        else if (model.list !== undefined && model.list.length > 1) {
            return (
                <>
                    <div className="multi-preferred-shopping-center">
                        <div className="bg-white p-lg-5 pt-lg-2" id="benefitsServices">
                            <div className="basic-benefits-container d-lg-none d-xl-none bg-white">
                                <div className="container modalContainer p-0 ">
                                    <nav className="benefits-header-height px-lg-1 row m-0   py-2">

                                        <div className="col-2 text-center px-0">
                                            <a className="picto icon-chevron-bottom d-flex align-items-center justify-content-center text-decoration-none text-black  fs-3"
                                                href="javascript:void(0);" data-testid="test4" onClick={(e) => { GoBackToMenu("my-benefits-and-services") }}></a>
                                        </div>
                                        <div className="col-8 p-0 text-center">

                                            <div className=" text-center benefits-subtitle ">
                                                {model.benefitsAndServicesTitle}
                                            </div>
                                        </div>
                                        <div className="col-2 text-center px-0">
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div
                                className="d-flex d-lg-block d-none d-xl-block justify-content-center text-align-center text-black shopping-center-name">{model.benefitsAndServicesTitle} </div>
                            <div className="text-black mob-pd shopping-center-subtitle">{model.multiSCDesc?.value}</div>
                            <div className="mob-pd">
                                {model.list.map((item, index) => {
                                    if (queryStringPcCode === item.pcCode) {
                                        dataItem = item;
                                        dataIndex = index;
                                        dataModel = model;
                                    }
                                    return (
                                        <a key={index} className="nav-link  border-gray shopping-center-box active"
                                            id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab"
                                            aria-controls="v-pills-home" aria-selected="true" data-testid="test5" onClick={(e) => { e.preventDefault(); displayBenefitsAndServicesMultiSC(item, index, model, true) }}>
                                            <span className="shopping-menu-nav-item justify-content-between d-flex">
                                                <div className="shopping-menu-text d-flex">
                                                    <div className="shopping-menu-title">{item.centerLabel}</div>
                                                    <div className="shopping-menu-county">{item.cityCountryData?.value}</div>
                                                </div>
                                                <span className="d-flex justify-content-center shopping-advantages" >
                                                    <span className="d-lg-block d-none d-xl-block shopping-redirect-text">{model.seeAdvAndServices}</span>
                                                    <i className="picto ms-3 no-underline icon-go-right-arrow shopping-icon-right-arrow text-black" ></i>
                                                </span>
                                            </span>
                                        </a>
                                    )
                                })
                                }
                            </div>


                        </div>
                        {(model.discoverMore !== undefined && model.discoverMore !== null && model.discoverMore !== "") &&
                            <div id="lpdiscovermyadvantagesmultisc">
                                <LPDiscoverMyAdvantages fields={model.discoverMore} />
                            </div>
                        }
                    </div>

                </>
            )
        }
        else if (model.list !== undefined && model.list.length == 1) {
            return (
                model.list.map((item, index) => {
                    if (queryStringShowSuccessPopin === "easy-parking" && queryStringPcCode === item.pcCode) {
                        monoScItem = item;
                    }
                    if (!(item.hasSmartParkerAvailable || item.hasSmartParkerActivated || (iLogsSmartParker && item.displayILogsParking))) {
                        if (document.getElementById("parkingmonosc")) {
                            document.getElementById("parkingmonosc").classList.add("d-none");
                        }
                    }
                    else {
                        if (document.getElementById("parkingmonosc")) {
                            document.getElementById("parkingmonosc").classList.remove("d-none");
                        }
                    }
                    return (<>
                        <div id="benefitsheadermonosc">
                        </div>
                        <div id="benefitsmonosc">
                        </div>
                        {(item.hasSmartParkerAvailable || item.hasSmartParkerActivated || (iLogsSmartParker&& item.displayILogsParking)) &&
                            <div id="parkingmonosc">
                            </div>
                        }
                        <div id="opinionmonosc">
                        </div>
                        <div id="loyaltyadvantagesmonosc">
                        </div>

                        {(!document.getElementById('hiddenElement') || document.getElementById("hiddenElement")?.value === undefined ||
                            document.getElementById("hiddenElement")?.value === "" || document.getElementById("hiddenElement")?.value === null ||
                            document.getElementById("hiddenElement")?.value !== item.pcCode) &&
                            displayBenefitsAndServicesMonoSC(item, index, fields)}


                    </>)
                }
                )
            )
        }
        else {
            return <></>
        }
    }
    else {
        return <></>
    }
}






