import Enumerable from "linq";
import { Image, RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect, useState } from "react";
import RoutableSitecoreLink from "../RoutableSitecoreLink";
import { fnGTMClickServiceOffer } from "../Js-CommonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { setSmartParkUser } from "../../redux/user/actions";

export default function GenericBlocks({ fields }) {
    var smartParkUser = useSelector((state) => state.user.smartParkUser);
    const dispatch = useDispatch();
    const [blockData, setBlockData] = useState([]);
    useEffect(() => {
        if (fields !== null && fields !== undefined && fields.model !== null && fields.model !== "" && fields.model !== undefined && fields.model.block_Details !== undefined) {
            if (model.block_Details.length > 0) {
                var activeData = Enumerable.from(model.block_Details).where(x => (x.block.personalized_Title !== null && x.block.personalized_Title !== "" && x.block.personalized_Title !== undefined)).toArray();
                setBlockData(activeData);
            }
        }
    })

    if (fields !== null && fields !== undefined && fields.model !== null && fields.model !== "" && fields.model !== undefined && fields.model.block_Details !== undefined) {
        var model = fields.model;

        if (smartParkUser !== model.isUserSmartParker)
            dispatch(setSmartParkUser(model.isUserSmartParker));
        return (
            <>
                {blockData.length > 0 &&
                    <section className="my-parking-service">
                        <div className="container">
                            {(model.title !== null && model.title !== "") &&
                                <h2 className="content-title text-uppercase">{model.title}</h2>
                            }
                            <div className="row">
                                {blockData.map((block, index) => {
                                    var buttonclass = block.hasDescription ? "btn btn-dark-transparent btn-bg-white" : "btn btn-dark";
                                    if (block.block.personalized_Title == null || block.block.personalized_Title == "") {
                                        return (<></>)
                                    }
                                    return (
                                        <div key={index} className={"col " + (block.hasDescription ? "js-hasdescription" : "")}>
                                            {(block.block.image !== null && block.block.image.value !== null && block.block.image?.value?.src !== null && block.block.image?.value?.src !== "") ?
                                                (
                                                    <div className="d-lg-flex position-relative mt-2 text-center visit-centre sm-img-bg-wrap ">
                                                        <div className="opacity_div"></div>
                                                        <Image field={block.blockObj["Image"]} className="card-img img-fluid" />
                                                        <div className="card-img-overlay d-lg-flex text-center box-border-shadow justify-content-between flex-column">
                                                            <div className="picto-wrap">
                                                                {block.block.personalized_Picto !== null && block.block.personalized_Picto !== "" ?
                                                                    (
                                                                        <span className={"picto " + (block.block.personalized_Picto) + " " + (block.picto_Background !== null && block.picto_Background !== "" ? block.picto_Background : "biege")}></span>
                                                                    ) :
                                                                    (
                                                                        <span className="picto icon-parking bg-white"></span>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="parking-service-wrap text-center">
                                                                <div className="title text-uppercase"><Text field={block.blockObj["Personalized Title"]} /></div>
                                                            </div>
                                                            {(block.ctA_Src !== null && block.ctA_Src !== "" && block.ctA_Text !== null && block.ctA_Text !== "") &&
                                                                <div className="cta-block text-center">
                                                                    {model.isPageEditor ?
                                                                        (<RoutableSitecoreLink fields={block.blockObj["Personalized CTA"]} className="btn btn-dark-transparent btn-bg-white" />) :
                                                                        (<a href={block.ctA_Src} aria-label={block.ctA_Target} target={block.ctA_Target} className={"btn btn-dark-transparent btn-bg-white " + (block.ctA_Class)} data-testid="gtmclicktest" onClick={(e) => fnGTMClickServiceOffer(block.ctA_Text)}>{block.ctA_Text}</a>)
                                                                    }
                                                                </div>
                                                            }
                                                            {(block.link_Src !== null && block.link_Src !== "" && block.link_Text !== null && block.link_Text !== "") &&
                                                                <div className="link-text-wrap">
                                                                    {model.isPageEditor ?
                                                                        (<RoutableSitecoreLink fields={block.blockObj["Personalized Link"]} className="link-text" />) :
                                                                        (<a href={block.link_Src} aria-label={block.ctA_Target} target={block.link_Target} className={"link-text " + block.link_Class} >{block.link_Text}</a>)
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                ) :
                                                (
                                                    <div className={"d-lg-flex position-relative mt-2 text-center box-border-shadow justify-content-between flex-column visit-centre " + (block.hasDescription ? "img-bgcolor-wrap arrow-down " : " ") + (block.block_Background !== null && block.block_Background !== "" ? block.block_Background : "") + (blockData.length === 1 ? " one-column-parking" : "")}>
                                                        <div className="picto-wrap">
                                                            {block.block.personalized_Picto !== null && block.block.personalized_Picto !== "" ?
                                                                (
                                                                    <span className={"picto " + (block.block.personalized_Picto) + " " + (block.picto_Background !== null && block.picto_Background !== "" ? block.picto_Background : "biege")}></span>
                                                                ) :
                                                                (
                                                                    <span className={"picto icon-parking " + ((block.picto_Background !== null && block.picto_Background !== "") ? block.picto_Background : "biege")}></span>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="parking-service-wrap text-center">
                                                            <div className="title text-uppercase"><Text field={block.blockObj["Personalized Title"]} /></div>
                                                        </div>
                                                        {(block.ctA_Src !== null && block.ctA_Src !== "" && block.ctA_Text !== null && block.ctA_Text !== "") &&
                                                            <div className="cta-block text-center">
                                                                {model.isPageEditor ?
                                                                    (<RoutableSitecoreLink fields={block.blockObj["Personalized CTA"]} className={buttonclass} />) :
                                                                    (<a href={block.ctA_Src} aria-label={block.ctA_Target} target={block.ctA_Target} className={buttonclass + " " + block.ctA_Class} data-testid="gtmclicktest" onClick={(e) => fnGTMClickServiceOffer(block.ctA_Text)} >{block.ctA_Text}</a>)
                                                                }
                                                            </div>
                                                        }
                                                        {(block.link_Src !== null && block.link_Src !== "" && block.link_Text !== null && block.link_Text !== "") &&
                                                            <div className="link-text-wrap">
                                                                {model.isPageEditor ?
                                                                    (<RoutableSitecoreLink fields={block.blockObj["Personalized Link"]} className="link-text" />) :
                                                                    (<a href={block.link_Src} aria-label={block.ctA_Target} target={block.link_Target} className={"link-text " + block.link_Class} >{block.link_Text}</a>)
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                }
                {(model.description_Blocks !== null && ((model.description_Blocks.block_1_Title !== null && model.description_Blocks.block_1_Title !== "") || (model.description_Blocks.block_1_Description !== null && model.description_Blocks.block_1_Description !== "") ||
                    (model.description_Blocks.block_2_Title !== null && model.description_Blocks.block_2_Title !== "") || (model.description_Blocks.block_2_Description !== null && model.description_Blocks.block_2_Description !== "") || (model.description_Blocks.block_3_Title !== null && model.description_Blocks.block_3_Title !== "") ||
                    (model.description_Blocks.block_3_Description !== null && model.description_Blocks.block_3_Description !== ""))) &&
                    <section className="smart-parking-wrap">
                        <div className="container">
                            <h1 className="content-title text-uppercase d-block text-center fw-normal"><span className="d-block fw-bold"><Text field={model.description_BlocksObj["Title"]} /></span></h1>
                            <div className="row">
                                {((model.description_Blocks.block_1_Title !== null && model.description_Blocks.block_1_Title !== "" && model.description_Blocks.block_1_Title?.value !== "") || (model.description_Blocks.block_1_Description !== null && model.description_Blocks.block_1_Description !== "")) &&
                                    <div className="col">
                                        <div className="d-flex smart-parking-content">
                                            <div className="card rounded-0 picto-block">
                                                <div className="picto-wrap">
                                                    <span className={"picto " + (model.description_Blocks.block_1_Icon_Web !== null && model.description_Blocks.block_1_Icon_Web !== "" ? model.description_Blocks.block_1_Icon_Web : "icon-parking")}></span>
                                                </div>
                                                <div className="card-body">
                                                    <h3 className="title text-uppercase"><RichText field={model.description_BlocksObj["Block 1 Title"]} /></h3>
                                                    <div className="content"><RichText field={model.description_BlocksObj["Block 1 Description"]} /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {((model.description_Blocks.block_2_Title !== null && model.description_Blocks.block_2_Title !== "" && model.description_Blocks.block_2_Title?.value !== "") || (model.description_Blocks.block_2_Description !== null && model.description_Blocks.block_2_Description !== "")) &&
                                    <div className="col">
                                        <div className="d-flex smart-parking-content">
                                            <div className="card rounded-0 picto-block">
                                                <div className="picto-wrap">
                                                    <span className={"picto " + (model.description_Blocks.block_2_Icon_Web !== null && model.description_Blocks.block_2_Icon_Web !== "" ? model.description_Blocks.block_2_Icon_Web : "icon-parking")}></span>
                                                </div>
                                                <div className="card-body">
                                                    <h3 className="title text-uppercase"><RichText field={model.description_BlocksObj["Block 2 Title"]} /></h3>
                                                    <div className="content"><RichText field={model.description_BlocksObj["Block 2 Description"]} /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {((model.description_Blocks.block_3_Title !== null && model.description_Blocks.block_3_Title !== "" && model.description_Blocks.block_3_Title?.value !== "") || (model.description_Blocks.block_3_Description !== null && model.description_Blocks.block_3_Description !== "")) &&
                                    <div className="col">
                                        <div className="d-flex smart-parking-content">
                                            <div className="card rounded-0 picto-block">
                                                <div className="picto-wrap">
                                                    <span className={"picto " + (model.description_Blocks.block_3_Icon_Web !== null && model.description_Blocks.block_3_Icon_Web !== "" ? model.description_Blocks.block_3_Icon_Web : "icon-parking")}></span>
                                                </div>
                                                <div className="card-body">
                                                    <h3 className="title text-uppercase"><RichText field={model.description_BlocksObj["Block 3 Title"]} /></h3>
                                                    <div className="content"><RichText field={model.description_BlocksObj["Block 3 Description"]} /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                }
            </>
        )
    }
    else {
        return (<></>)
    }
}
