import React, { useEffect, useState } from 'react';
import { fnApplyPyjamaEffect, webViewEqualsOne } from '../Js-CommonFunctions';
import { baseModel } from '../../RouteHandler';
import ServicesFilterInner from '../ServicesFilterInner';
import { useSelector } from 'react-redux';
import axios from 'axios';

export default function HomePageServices({ fields }) {
  let serviceCounter = 0;
  let workerCenter = useSelector((state) => state.user.workerCenter);
  const [servicesData, setServicesData] = useState();

  useEffect(() => {
    let isWorker =
      workerCenter !== undefined &&
      workerCenter !== '' &&
      workerCenter.split(',').includes(baseModel.pC_Code);
    if (isWorker) {
      var loader = document.getElementById("homepageservicesloader")

      if (loader) {
        loader.classList.remove("invisible");
        loader.classList.add("visible");
      }
      var ajaxServicesURL =
        "/api/sitecore/ServiceApi/GetServicesViewMore?ursite=" +
        baseModel.urContextSite +
        "&isWorker=" +
        isWorker +
        "&urlang=" +
        baseModel.contextLang;
      axios({
        method: "GET",
        url: ajaxServicesURL,
        async: true,
      })
        .then(function (response) {
          if (response?.data?.servicesList !== null && response?.data?.servicesList !== "" && response?.data?.servicesList !== undefined && response?.data?.servicesList.length > 0) {
            setServicesData(response.data);
            if (document.getElementById("homePageServices"))
              document.getElementById("homePageServices").style.display = "block";
          }
          else {
            if (document.getElementById("homePageServices"))
              document.getElementById("homePageServices").style.display = "none";
            fnApplyPyjamaEffect();
          }
          webViewEqualsOne();
          if (loader) {
            loader.classList.add("invisible");
            loader.classList.remove("visible");
          }
          // var numberOfBlocks = document.querySelectorAll(
          //   "#services .service-block"
          // ).length;
          // if (numberOfBlocks > 0) {
          //   if (
          //     document.getElementById("spnServicesCount") != undefined &&
          //     document.getElementById("spnServicesCount") != null &&
          //     document.getElementById("spnServicesCount").length > 0
          //   ) {
          //     document.querySelectorAll("#spnServicesCount")[0].innerText =
          //       "(" + document.getElementById("hdnServiceCount").value + ")"; //bind the Total services count from the hidden field in Partial view to the span in Main view
          //   }

          //   document
          //     .querySelectorAll("#services .gtmClickNavigation")
          //     .forEach((x) => {
          //       x.addEventListener("click", function (e) {
          //         fnClickNavigation(e.currentTarget);
          //       });
          //     });
          // } else {
          //   if (document.querySelectorAll("homePageServices")[0])
          //     document.querySelectorAll("homePageServices")[0].style.display =
          //       "none"; //hide the Services component component when there are no services found

          // }
        })
        .catch(function (error) {
          console.log(error);
          if (loader) {
            loader.classList.add("invisible");
            loader.classList.remove("visible");
          }
          if (document.querySelectorAll("#homePageServices")[0] !== undefined)
            document.querySelectorAll("#homePageServices")[0].style.display =
              "none"; //hide the HomePageServices component when there are no services found
        });
    }
    else {
      if (fields?.centerServiceData?.servicesList !== null && fields?.centerServiceData?.servicesList !== "" && fields?.centerServiceData?.servicesList !== undefined && fields?.centerServiceData?.servicesList.length > 0)
        setServicesData(fields.centerServiceData);
      else {
        if (document.getElementById("homePageServices"))
          document.getElementById("homePageServices").style.display = "none";
        fnApplyPyjamaEffect();
      }
    }
  }, []);


  if (fields && fields.homePageService != null) {
    return (
      <section className="back-gray services position-relative" id="homePageServices" data-loadcontentasync={(fields.homePageService.isExperienceEditor ? false : true)} >
        <div className="container" id="homePageServiceContainer">
          <div className="header-title d-flex align-items-baseline flex-wrap justify-content-lg-between">
            {(fields.homePageService.isServicePageExists) ?
              (
                <>
                  <a aria-label={fields?.homePageService?.homeServiceTitle} className="title gtmClickNavigation" href={fields.homePageService.servicePage}>
                    {/* {(!fields.homePageService.isNewBrandedSite) ?
                      (
                        <h2>{fields.homePageService.homeServiceTitle}</h2>
                      ) :
                      (
                        <h2><span className="title-border">{fields.homePageService.homeServiceTitle}</span></h2>
                      )
                    } */}
                    <h2><span className="news-title">{fields.homePageService.homeServiceTitle}</span></h2>
                  </a>
                  {/* <a className={"action" + (fields.homePageService.isNewBrandedSite ? " picto icon-plus-big" : " ") + " gtmClickNavigation"} aria-label="services-link" href={fields.homePageService.servicePage}>
                    {(!fields.homePageService.isNewBrandedSite) &&
                      <>
                        <strong>{fields.homePageService.homeServicesSeeAll} </strong><span id="spnServicesCount" className="counterLabel">({fields.centerServiceData.totalServicesCount})</span><span className="picto icon-ico-chevron-right"></span>
                      </>
                    }
                  </a> */}
                </>
              ) :
              (
                //need to check with Aditya
                <div className="title mb-1 mb-lg-0" style={{ cursor: "default" }}>
                  {/* {(!fields.homePageService.isNewBrandedSite) ?
                    (<h2>{fields.homePageService.homeServiceTitle}</h2>
                    ) :
                    <h2><span className="title-border">{fields.homePageService.homeServiceTitle}</span></h2>
                  } */
                  }
                  <h2><span className="news-title">{fields.homePageService.homeServiceTitle}</span></h2>
                </div>
              )
            }
          </div>
          {(fields.homePageService.isExperienceEditor) ?
            (
              (fields.centerServiceData != null && fields.centerServiceData.servicesList != null && fields.centerServiceData.servicesList.length > 0) &&
              <div className={fields.centerServiceData.display_Picture_For_Services ? "d-flex all-services home-services" : "search-wrapper"} id="services">
                {fields.centerServiceData.servicesList.map((item, index) => {
                  serviceCounter++;
                  return (
                    (fields.centerServiceData.display_Picture_For_Services) ?
                      (
                        (item.image != "") ?
                          (
                            <div key={index} className="card rounded-0 border-0 img-block  service-block">
                              <div className="img-wrap bg-transparent position-relative">
                                <img src={item.image} className="card-img-top rounded-0" alt={item.serviceData.shortTitle} />
                                <div className="service-picto-container">
                                  {fields.homePageService.isNewBrandedSite ?
                                    <img className="westfield-club-logo object-fit-contain w-0 d-flex m-auto" loading="lazy" src={baseModel.westfieldClubLogo?.value?.src} alt={baseModel.westfieldClubLogo?.value?.alt} title="club" />
                                    :
                                    <img className="westfield-club-logo object-fit-contain w-0 d-flex m-auto" loading="lazy" src={baseModel.clubLogo?.value?.src} alt={baseModel.clubLogo?.value?.alt} title="club" />
                                  }
                                  {/* <span className={"picto " + (item.serviceData.pictoOn != "" ? item.serviceData.pictoOn : "icon-servicesaspictosservices")}></span> */}
                                </div>
                              </div>
                              <div className="card-body d-flex flew-row align-items-center px-0">
                                <span className="picto icon-tag-rw align-middle px-0 fs-5"></span>
                                <a href={item.serviceUrl} aria-label={item.serviceUrl} className="d-flex align-items-center justify-content-center stretched-link gtmClickNavigation" >
                                  <div className="title">{item.serviceData.shortTitle}</div>
                                </a>
                              </div>
                            </div>
                          ) :
                          (
                            <div key={index} className="col-6 col-sm-4 col-md-3 col-lg-3 mb-1 pe-1 pe-lg-2">
                              <div className="block store-wrap with-logo">
                                <div className="store-img">
                                  <span className={"picto " + (item.serviceData.pictoOn != "" ? item.serviceData.pictoOn : "icon-servicesaspictosservices") + " border-8"}></span>
                                </div>
                              </div>
                              {/* <div className="card-body"> */}
                              <a href={item.serviceUrl} aria-label={item.serviceUrl} className="d-flex store-name text-start stretched-link gtmClickNavigation" >
                                <div className="title">{item.serviceData.shortTitle}</div>
                              </a>
                              {/* </div> */}
                            </div>
                          )
                      ) :
                      (
                        <div key={index} className="categories d-flex flex-row ">
                          <div className="search-col mb-1 pe-1 pe-lg-2">
                            <div className='block store-wrap with-logo'>
                              <div className='store-img'>
                                <span className={"picto " + (item.serviceData.pictoOn != "" ? item.serviceData.pictoOn : "icon-servicesaspictosservices") + " border-8"}></span>
                                <a href={item.serviceUrl} aria-label={item.serviceUrl} className="d-flex store-name text-start stretched-link gtmClickNavigation" >
                                  {item.serviceData.shortTitle}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )
                })}
                <input type="hidden" id="hdnServiceCount" value={fields.centerServiceData.totalServicesCount} />
              </div>
            ) :
            (
              <>
                <div className="loadersmall asyncloader invisible" id="homepageservicesloader" data-counterid="hdnServiceCount" data-parent="homePageServices">
                  <div className="dot-spin top-left"></div>
                </div>
                {servicesData !== null && servicesData !== undefined && servicesData !== "" &&
                  <div className="service-container" id="services">
                    <ServicesFilterInner model={servicesData} />
                  </div>
                }
              </>
            )
          }
          <div className="d-flex justify-content-center my-3">
            <a className="link-button link-button-font btn-width mb-lg-0 border border-1 border-dark rounded-pill mb-3" 
            href={fields?.homePageService?.servicePage}>{fields?.homePageService?.homeServicesSeeAll}
              <span className="button-color">{" (" + servicesData?.totalServicesCount + ")"}</span></a>
          </div>
        </div>
        {/* <button >{servicesData?.totalServicesCount}</button> */}
      </section>
    );
  } else {
    return <></>;
  }
}
