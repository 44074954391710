import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';


export default function OpeningHours({ fields }) {

  if (fields.openinghrs != null) {
    let divMapping = 0;
    return (
      (fields.openinghrs.customOpeningHrslist.length > 0) &&
      <section className='bg-2' id="opening-hours">
        <div className="container">
          <div className={fields.openinghrs.cls1}>
            {(fields.openinghrs.title !== "" && fields.openinghrs.title !== null) &&
              <h2 className={(fields.openinghrs.isNewBrandedSite ? " page-title" : " content-title") + "text-uppercase text-center" + (fields.openinghrs.isNewBrandedSite ? "" : " underline")}>{fields.openinghrs.title}</h2>
            }
            <div className="row isflex justify-content-center">
              {fields.openinghrs.customOpeningHrslist.map((itemblock, index) => {
                divMapping++;
                var openingHrslistName = { value: itemblock.openingHrslist.name !== "" && itemblock.openingHrslist.name !== null ? itemblock.openingHrslist.name : "" }
                var pictogramWithStyle = { value: itemblock.openingHrslist.pictogramWithStyle !== "" && itemblock.openingHrslist.pictogramWithStyle !== null ? itemblock.openingHrslist.pictogramWithStyle : "" }
                var hasPicto = itemblock.openingHrslist.pictogram !== "" && itemblock.openingHrslist.pictogram !== null && itemblock.openingHrslist.pictogram !== undefined ? true : false
                return (
                  <div key={index} className="col-md-3 col-sm-6 col-12 d-flex align-items-lg-stretch">
                    <div className="item bg-gray border-8 px-3">
                      {hasPicto &&
                        (pictogramWithStyle !== "") &&
                        <RichText field={pictogramWithStyle} />
                      }
                      {(openingHrslistName !== "") &&
                        <h3 className="whats-hot-title text-uppercase mt-2">
                          <span>
                            <RichText field={openingHrslistName} />
                          </span>
                        </h3>
                      }
                      <div className="description" >
                        {itemblock.seeAllOpeningHours.map((showTime, index) => {
                          return (
                            <p key={index}>
                              <span>
                                {showTime.days}
                              </span>
                              :<br />
                              <span className=" title-light">{showTime.eachShowTimesKeys}</span>
                            </p>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    );

  } else {
    return <></>;
  }
}

