import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseModel, global } from "../../RouteHandler";

var validation = false;

export default function MeansofContact({ fields, rendering }) {
  const { antiForgeryToken } = rendering;

  const [boolUseEffect, setBoolUseEffect] = useState(false); //some variables needs to be set and passed in second useEffect to overcome useEffect behaviour of not getting called in first render
  useEffect(() => {
    setBoolUseEffect(true);
    var js = document.createElement("script");
    js.type = "text/javascript";
    js.src =
      "https://www.google.com/recaptcha/api.js?onload=onloadReCaptchaCallback&render=explicit&hl=" +
      fields.meansofContact.selectedLanguageISO;
    document.body.appendChild(js);


  }, []);

  useEffect(() => {
  }, [boolUseEffect]);



  function sendemail(event, currentUrl) {

    document.getElementById("contactsubmit").disabled = true;

    if (!validation) {
      event.preventDefault();

      document.getElementById("contactsubmit").disabled = false;
      return false;
    } else {
      event.preventDefault();


      //axios call
      var form = document.getElementById("mailcontactform");
      var serializedForm = new FormData(form);
      serializedForm.append(antiForgeryToken?.name, antiForgeryToken?.value);
    }
    axios({
      method: "post",
      url: currentUrl,
      data: serializedForm,
    })
      .then(function (result) {
        document.getElementById("contactsubmit").disabled = false;
        fnGtmSumbitContact();
        window.location.reload();

      })
      .catch(function (error) {
        console.log(error)

      });
  }


  function fnGtmSumbitContact() {
    if (!global.isGTMTaggingEnabled) {
      return;
    }
    window.dataLayer.push({
      event: 'contact_submitted',
    })
  }

    return (
      <section>
        <div className="container">
          <div className="sign-in contact" id="signIn">
            {fields.meansofContact.means_Of_Contact_Task && (
              <h2 className={"content-title d-flex justify-content-start "+(fields.meansofContact.isNewBrandedSite ?"":"")}>
                {fields.meansofContact.means_Of_Contact_Task}
              </h2>
            )}
            <form
              className="global-form contact-form"
              noValidate
              method="post"
              data-testid="sendemail1"
              onSubmit={(e) => sendemail(e, fields.meansofContact.sendMailAction)}
              id="mailcontactform"
            >
              <input
                type="hidden"
                id="captchaSiteKey"
                value={baseModel.captchaSiteKey}
              />

              <div className="row">
                <div className="col-sm-12 col-lg-12 p-0">

                  <div className="form-group input-radio">
                    <div className="legend">
                      {fields.meansofContact.means_Of_Contact_Title + "*"}
                    </div>
                    <div className="form-check-inline input-radio mw-100">
                      <label htmlFor="ms" className="ms form-label me-2" id="mslabel">
                        {fields.meansofContact.means_Of_Contact_Ms}
                        <input
                          data-cs-mask
                          className="idz_hide"
                          type="radio"
                          name="ms"
                          id="ms"
                          value="ms"
                          data-testid="TitleCheckMeansClick1"
                          onClick={(e) => {
                            return TitleCheckMeans();
                          }}
                        />
                      </label>
                      <label htmlFor="mr" className="mr form-label me-2" id="mrlabel">
                        {fields.meansofContact.means_Of_Contact_Mr}
                        <input
                          type="radio"
                          name="ms"
                          id="mr"
                          value="mr"
                          data-testid="TitleCheckMeansClick2"
                          onClick={(e) => {
                            return TitleCheckMeans();
                          }}
                        />
                      </label>
                      {/* <label htmlFor="prefernottosay" className="mr form-label " id="prefernottosaylabel">
                        {fields.meansofContact.means_Of_Contact_Mr}
                        <input
                          type="radio"
                          name="ms"
                          id="prefernottosay"
                          value="prefernottosay"
                          data-testid="TitleCheckMeansClick3"
                          onClick={(e) => {
                            return TitleCheckMeans();
                          }}
                        />
                      </label> */}
                    </div>
                    <input
                      type="hidden"
                      id="lblms"
                      name="lblms"
                      value={fields.meansofContact.means_Of_Contact_Title}
                    />
                    <span
                      id="TitleCheck"
                      data-validatormsg={fields.meansofContact.validation_Title}
                      className="error"
                    ></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 p-0">
                  <div className="mb-4">
                    <label className="form-label" htmlFor="firstname">
                      {fields.meansofContact.means_Of_Contact_First_Name + "*"}
                    </label>
                    <input
                      type="hidden"
                      id="lblfirstname"
                      name="lblfirstname"
                      value={fields.meansofContact.means_Of_Contact_First_Name}
                    />
                    <input
                      data-cs-mask
                      className="form-control idz_hide"
                      type="text"
                      name="firstname"
                      id="firstname"
                      aria-required="true"
                      data-testid="AlphabetsValidationMeans1"
                      onBlur={(e) => {
                        return AlphabetsValidationMeans();
                      }}
                      maxLength="50"
                    />
                    <span
                      id="firstname-error"
                      data-validatormsg1={fields.meansofContact.validate_Name}
                      data-validatormsg2={
                        fields.meansofContact.validation_Valid_Name
                      }
                      className="error"
                    ></span>
                  </div>
                </div>

                <div className="col-sm-6 p-0">
                  <div className="mb-4">
                    <label htmlFor="lastname">
                      {fields.meansofContact.means_Of_Contact_Last_Name + "*"}
                    </label>
                    <input
                      type="hidden"
                      id="lbllastname"
                      name="lbllastname"
                      value={fields.meansofContact.means_Of_Contact_Last_Name}
                      maxLength="50"
                    />
                    <input
                      data-cs-mask
                      className="form-control idz_hide"
                      type="text"
                      name="lastname"
                      id="lastname"
                      aria-required="true"
                      data-testid="AlphabetsValidationMeans2"
                      onBlur={(e) => {
                        return AlphabetsValidationMeans();
                      }}
                      maxLength="50"
                    />
                    <span
                      id="lastname-error"
                      data-validatormsg1={
                        fields.meansofContact.validation_Last_Name
                      }
                      data-validatormsg2={
                        fields.meansofContact.validation_Valid_Name
                      }
                      className="error"
                    ></span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 p-0">
                  <div className="mb-4">
                    <label htmlFor="email" className="form-label">
                      {fields.meansofContact.means_Of_Contact_Email + "*"}
                    </label>
                    <input
                      type="hidden"
                      className="form-control"
                      id="lblemail"
                      name="lblemail"
                      value={fields.meansofContact.means_Of_Contact_Email}
                    />
                    <input
                      data-cs-mask
                      className="form-control idz_hide"
                      type="email"
                      name="email"
                      id="email"
                      aria-required="true"
                      data-testid="emailvalidate1"
                      onBlur={(e) => {
                        return emailvalidate();
                      }}
                    />
                    <span
                      id="email-error"
                      data-validatormsg1={
                        fields.meansofContact.validation_Valid_Email
                      }
                      data-validatormsg2={fields.meansofContact.validate_Email}
                      className="error"
                    ></span>
                  </div>
                </div>
              </div>

              <div className="mb-4 row">
                {/* <div className="form-group"> */}
                <label htmlFor="yourmessage" className="form-label ps-0">
                  {fields.meansofContact.means_Of_Contact_Message + "*"}
                </label>
                <input
                  className="form-control"
                  type="hidden"
                  id="lblmessage"
                  name="lblmessage"
                  value={fields.meansofContact.means_Of_Contact_Message}
                />
                <textarea
                  className="form-control"
                  rows="4"
                  name="yourmessage"
                  id="yourmessage"
                  aria-required="true"
                ></textarea>
                <span
                  id="yourmessage-error"
                  data-validatormsg={
                    fields.meansofContact.means_Of_Contact_Message
                  }
                  className="error ps-0"
                ></span>
                {/* </div> */}
              </div>
              <div className="row">
                <div className="recaptcha-wrapper px-0">
                  {fields.meansofContact.isRecaptchaValid && (
                    <>
                      <div className="recaptcha-wrapper px-0">
                        <div id="dvCaptcha" className="g-recaptcha"></div>
                      </div>
                      <span
                        id="recaptcha-error-message"
                        data-validatormsg1={
                          fields.meansofContact.emptyFieldValidationText
                        }
                        data-validatormsg2={fields.meansofContact.captchaError}
                        className="error"
                      ></span>
                    </>
                  )}
                </div>
              </div>
              {/* <div className="col-12 col-lg-6">
                  {" "} */}
              <div className="row mandatory-info">
                {"*" + fields.meansofContact.means_Of_Contact_Mandatory}
              </div>
              {/* </div> */}

              <div className="row mb-4 btn-submit d-flex align-items-start ">
                <button
                  className="btn btn-dark contact-button analytics_contactbuttonclk"
                  id="contactsubmit"
                  type="submit"
                  data-testid="formsubmit1"
                  onClick={(e) => {
                    return formsubmit(e, fields.meansofContact.isRecaptchaValid);
                  }}
                >
                  {fields.meansofContact.send_It}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
 
}

function formsubmit(e, isCaptchaExists) {

  var validationcheck = true;
  var existingValidationFlag;
  var nospecial = /^[^*|\":<>[\]{}`\\();@&$]+$/;
  document.querySelectorAll('input[type="text"]').forEach((x) => {
    x.classList.remove("error");
  });

  document.getElementById("contactsubmit").setAttribute("data-gtmerror", "[");
  var error = document
    .getElementById("contactsubmit")
    .getAttribute("data-gtmerror");
  //Title Validation
  var title = document.getElementsByName("ms");
  if (title[0].checked || title[1].checked) {
    document.getElementById("TitleCheck").innerHTML = "";
  } else {
    document.getElementById("TitleCheck").innerHTML = document
      .getElementById("TitleCheck")
      .getAttribute("data-validatormsg")
      .valueOf();
    var error =
      document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
      "{Title:Blank}" +
      ",";
    document
      .getElementById("contactsubmit")
      .setAttribute("data-gtmerror", error);
    validationcheck = false;
  }

  var fname = document.getElementById("firstname").value;
  var lname = document.getElementById("lastname").value;
  document.getElementById("firstname-error").innerHTML = "";
  document.getElementById("lastname-error").innerHTML = "";
  if (fname == "") {
    document.getElementById("firstname-error").innerHTML = document
      .getElementById("firstname-error")
      .getAttribute("data-validatormsg1")
      .valueOf();
    document.getElementById("firstname-error").style.display = "block";
    document.getElementById("firstname").classList.add("error");
    var error =
      document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
      "{First Name:Blank}" +
      ",";
    document
      .getElementById("contactsubmit")
      .setAttribute("data-gtmerror", error);
    validationcheck = false;
  } else if (!fname.match(nospecial)) {
    document.getElementById("firstnameReg").classList.add("error");
    var error =
      document.getElementById("regSubmit").getAttribute("data-gtmerror") +
      "{First Name:Invalid}" +
      ",";
    document.getElementById("regSubmit").setAttribute("data-gtmerror", error);
    if (existingValidationFlag) {
      var elementfocus = "firstnameReg";
      existingValidationFlag = false;
    }
  } else if (!fname.match(/^[A-Za-z\u00C0-\u017F-' ]+$/)) {
    document.getElementById("firstname-error").innerHTML = document
      .getElementById("firstname-error")
      .getAttribute("data-validatormsg2")
      .valueOf();
    document.getElementById("firstname-error").style.display = "block";
    document.getElementById("firstname").classList.add("error");
    var error =
      document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
      "{First Name:Invalid}" +
      ",";
    document
      .getElementById("contactsubmit")
      .setAttribute("data-gtmerror", error);
    validationcheck = false;
  }

  if (lname == "") {
    document.getElementById("lastname-error").innerHTML = document
      .getElementById("lastname-error")
      .getAttribute("data-validatormsg1")
      .valueOf();
    document.getElementById("lastname-error").style.display = "block";
    document.getElementById("lastname").classList.add("error");
    var error =
      document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
      "{Last Name:Blank}" +
      ",";
    document
      .getElementById("contactsubmit")
      .setAttribute("data-gtmerror", error);
    validationcheck = false;
  } else if (!lname.match(nospecial)) {
    document.getElementById("lastname-error").innerHTML = document
      .getElementById("lastname-error")
      .getAttribute("data-validatormsg2")
      .valueOf();
    document.getElementById("lastname-error").style.display = "block";
    var error =
      document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
      "{Last Name:Invalid}" +
      ",";
    document
      .getElementById("contactsubmit")
      .setAttribute("data-gtmerror", error);
    document.getElementById("lastname").classList.add("error");
    validationcheck = false;
  } else if (!lname.match(/^[A-Za-z\u00C0-\u017F-' ]+$/)) {
    document.getElementById("lastname-error").innerHTML = document
      .getElementById("lastname-error")
      .getAttribute("data-validatormsg2")
      .valueOf();
    document.getElementById("lastname-error").style.display = "block";
    var error =
      document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
      "{Last Name:Invalid}" +
      ",";
    document
      .getElementById("contactsubmit")
      .setAttribute("data-gtmerror", error);
    document.getElementById("lastname").classList.add("error");
    validationcheck = false;
  }

  var emailid = document.getElementById("email").value;
  document.getElementById("email-error").innerHTML = "";
  if (emailid == "") {
    document.getElementById("email-error").innerHTML = document
      .getElementById("email-error")
      .getAttribute("data-validatormsg2")
      .valueOf();
    document.getElementById("email-error").style.display = "block";
    document.getElementById("email").classList.add("error");
    var error =
      document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
      "{Email:Blank}" +
      ",";
    document
      .getElementById("contactsubmit")
      .setAttribute("data-gtmerror", error);
    validationcheck = false;
  } else {
    var atpos = emailid.indexOf("@");
    var dotpos = emailid.lastIndexOf(".");
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailid.length) {
      document.getElementById("email-error").innerHTML = document
        .getElementById("email-error")
        .getAttribute("data-validatormsg1")
        .valueOf();
      document.getElementById("email-error").style.display = "block";
      document.getElementById("email").classList.add("error");
      var error =
        document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
        "{Email:Invalid}" +
        ",";
      document
        .getElementById("contactsubmit")
        .setAttribute("data-gtmerror", error);
      validationcheck = false;
    }
  }

  emailid = document.getElementById("yourmessage").value;
  document.getElementById("yourmessage-error").innerHTML = "";
  document.getElementById("yourmessage-error").classList.add("collapse");
  if (emailid == "") {
    document.getElementById("yourmessage-error").innerHTML = document
      .getElementById("yourmessage-error")
      .getAttribute("data-validatormsg")
      .valueOf();
    document.getElementById("yourmessage-error").classList.remove("collapse");
    document.getElementById("yourmessage-error").style.display = "block";
    document.getElementById("yourmessage").classList.add("error");
    var error =
      document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
      "{Message:Blank}" +
      ",";
    document
      .getElementById("contactsubmit")
      .setAttribute("data-gtmerror", error);
    validationcheck = false;
  }
  if (error.charAt(error.length - 1) == ",") {
    var error = error.slice(0, -1);
    document
      .getElementById("contactsubmit")
      .setAttribute("data-gtmerror", error);
  }
  var error =
    document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
    "]";
  document.getElementById("contactsubmit").setAttribute("data-gtmerror", error);

  if (isCaptchaExists) {
    var captcha_response = window.grecaptcha.getResponse();
    if (captcha_response.length == 0) {
      document
        .getElementsByClassName("recaptcha-wrapper")[0]
        .classList.add("error");
      document.getElementById("recaptcha-error-message").innerHTML = document
        .getElementById("recaptcha-error-message")
        .getAttribute("data-validatormsg1")
        .valueOf();
      document.getElementById("recaptcha-error-message").style.display =
        "block";
      document.getElementById("recaptcha-error-message").classList.add("error");
      var error =
        document.getElementById("contactsubmit").getAttribute("data-gtmerror") +
        "{Captcha:Blank/Invalid}" +
        ",";
      document
        .getElementById("contactsubmit")
        .setAttribute("data-gtmerror", error);
      validationcheck = false;
    }
  }

  validation = validationcheck;
  // fnGTMErrorEvent(e.currentTarget);
  return validationcheck;
}

function TitleCheckMeans() {
  var title = document.getElementsByName("ms");
  if (title[0].checked) {
    document.getElementById("mrlabel").classList.remove("active");
    document.getElementById("mslabel").classList.add("active");
  }
  if (title[1].checked) {
    document.getElementById("mslabel").classList.remove("active");
    document.getElementById("mrlabel").classList.add("active");
  }


  if (title[0].checked || title[1].checked) {
    document.getElementById("TitleCheck").innerHTML = "";
    return true;
  } else {
    document.getElementById("TitleCheck").innerHTML = document
      .getElementById("TitleCheck")
      .getAttribute("data-validatormsg")
      .valueOf();
  }
  return false;
}

function AlphabetsValidationMeans() {
  var validationcheck = true;
  var nospecial = /^[^*|\":<>[\]{}`\\();@&$]+$/;
  var fname = document.getElementById("firstname").value;
  var lname = document.getElementById("lastname").value;

  if (fname == "") {
    document.getElementById("firstname-error").innerHTML = document
      .getElementById("firstname-error")
      .getAttribute("data-validatormsg1")
      .valueOf();
    document.getElementById("firstname-error").style.display = "block";
    validationcheck = false;
  } else if (!fname.match(nospecial)) {
    document.getElementById("firstname-error").innerHTML = document
      .getElementById("firstname-error")
      .getAttribute("data-validatormsg2")
      .valueOf();
    document.getElementById("firstname-error").style.display = "block";
    validationcheck = false;
  } else if (!fname.match(/^[A-Za-z\u00C0-\u017F- ']+$/)) {
    document.getElementById("firstname-error").innerHTML = document
      .getElementById("firstname-error")
      .getAttribute("data-validatormsg2")
      .valueOf();
    document.getElementById("firstname-error").style.display = "block";
    validationcheck = false;
  } else {
    document.getElementById("firstname-error").innerHTML = "";
  }

  if (lname == "") {
    document.getElementById("lastname-error").innerHTML = document
      .getElementById("lastname-error")
      .getAttribute("data-validatormsg1")
      .valueOf();
    document.getElementById("lastname-error").style.display = "block";
    validationcheck = false;
  } else if (!lname.match(nospecial)) {
    document.getElementById("lastname-error").innerHTML = document
      .getElementById("lastname-error")
      .getAttribute("data-validatormsg2")
      .valueOf();
    document.getElementById("lastname-error").style.display = "block";
    validationcheck = false;
  } else if (!lname.match(/^[A-Za-z\u00C0-\u017F- ']+$/)) {
    document.getElementById("lastname-error").innerHTML = document
      .getElementById("lastname-error")
      .getAttribute("data-validatormsg2")
      .valueOf();
    document.getElementById("lastname-error").style.display = "block";
    validationcheck = false;
  } else {
    document.getElementById("lastname-error").innerHTML = "";
  }
  if (validationcheck) {
    validation = true;
  }

  return validationcheck;
}

function emailvalidate() {
  var emailid = document.getElementById("email").value;
  document.getElementById("email-error").innerHTML = "";
  var atpos = emailid.indexOf("@");
  var dotpos = emailid.lastIndexOf(".");
  if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailid.length) {
    document.getElementById("email-error").innerHTML = document
      .getElementById("email-error")
      .getAttribute("data-validatormsg1")
      .valueOf();
    document.getElementById("email-error").style.display = "block";
    return false;
  }
  return true;
}
