import { RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect } from "react";
import { CommonBookmarkallpage, fnGtmOfferClick, offersClick, offersTooltipOnHover } from '../Js-CommonFunctions';
import { baseModel } from '../../RouteHandler';
import { useSelector } from 'react-redux';

export default function ShopLandingOfferInnerPage({ model, landingOfferModel, values, isClubPage, isMobileView }) {

  useEffect(() => {
    CommonBookmarkallpage();
    offersTooltipOnHover();
  }, [])
  var visitorId = useSelector((state) => state.user.visitorId)

  let isDetailPage = model.isStoreOrRestaurantDetailPage;
  let isOffersPage = (baseModel.currentPageDisplayName === landingOfferModel.loyaltyOfferPage || baseModel.currentPageItemName === landingOfferModel.loyaltyOfferPage);//landingOfferModel.url.includes(landingOfferModel.slash + landingOfferModel.loyaltyOfferPage);
  let strEligibilityLoyaltyText = model.eligibilityLoyaltyText;
  let strEligibilityWorkerText = model.eligibilityWorkerText;

  let isNewBrandedSite = model.isNewBrandedSite;
  let offerIndex = 0;
  var currentPage = model != null && model.strContextPageName != null ? model.strContextPageName : "";
  let styleClassName = isOffersPage ? "col-lg-4" : "col-lg-3";

  if (model !== null) {
    return (
      <>
        {(model.offerItems !== null && model.offerItems !== undefined) && model.offerItems.slice(0, values).map((offeritems, index) => {

          let strEligibility = landingOfferModel.eligibilityForAll;
          var subtitle = { value: offeritems.offer.subtitle };

          if (offeritems != null) {
            offerIndex++;
            let storeName = "";
            let offerRetailerName = '';
            let isOfferLogo = true;
            let isOfferExternal = true;
            let logoSource = "";
            let logoAltText = "";
            let offerLink = "";
            let OfferStoreName = "";
            let retailerName = "";
            let offerStickerName = "";
            let offerTitle = "";
            let strCommercialOperation = offeritems.commercialOperation != null && (offeritems.commercialOperation.name != null && offeritems.commercialOperation.name != "") ? offeritems.commercialOperation.name : "";



            if (offeritems.commercialOperation != null && offeritems.commercialOperation.categoryId !== landingOfferModel.commercialTypeExternal) {
              if (offeritems.storeitem != null) {

                storeName = ((offeritems.storeitem != null && offeritems.storeitem.name != null && offeritems.storeitem.name != "") ? offeritems.storeitem.name : "");
                offerRetailerName = ((offeritems.storeitem != null && offeritems.storeitem.retailerName != null && offeritems.storeitem.retailerName != "") ? offeritems.storeitem.retailerName : "");
                isOfferLogo = offeritems.imgLogoURL != null && offeritems.imgLogoURL != "";
                isOfferExternal = false;
                logoSource = offeritems.imgLogoURL;
                logoAltText = offeritems.imgLogoAltText;
                offerLink = offeritems.storeLinkURL.toString().startsWith(".") ? offeritems.storeLinkURL.toString().slice(1) : offeritems.storeLinkURL;
                OfferStoreName = ((offeritems.storeitem != null && offeritems.storeitem.name != null && offeritems.storeitem.name != "") ? offeritems.storeitem.name : "");
                offerStickerName = (offeritems != null && offeritems.offer != null) ? offeritems.offer.sticker : "";
                retailerName = OfferStoreName;
                offerTitle = ((offeritems.offer != null && offeritems.offer.title != null && offeritems.offer.title != "") ? offeritems.offer.title : "");
              }
              else {

                isOfferLogo = offeritems.externalOfferLogo != null && offeritems.externalOfferLogo != "";
                isOfferExternal = false;
                logoSource = offeritems.externalOfferLogo.includes(landingOfferModel.imgCloudinary) ? offeritems.scaleExternalOfferLogo :
                  offeritems.maxExternalOfferLogo;
                logoAltText = offeritems.externalOfferLogoAlt;
                offerLink = visitorId != null && visitorId != "" ? (offeritems.externalOfferLink + "?FidID=" + visitorId) : offeritems.externalOfferLink;
                offerStickerName = (offeritems != null && offeritems.offer != null) ? offeritems.offer.sticker : "";
                OfferStoreName = ((offeritems.offer != null && offeritems.offer.title != null && offeritems.offer.title != "") ? offeritems.offer.title : "");
                retailerName = ((offeritems != null && offeritems.offer != null && offeritems.offer.title != null && offeritems.offer.title != "") ? offeritems.offer.title : "");
                offerTitle = OfferStoreName;
              }
            }
            else {

              isOfferLogo = offeritems.externalOfferLogo != null && offeritems.externalOfferLogo != "";
              logoSource = offeritems.externalOfferLogo.includes(landingOfferModel.imgCloudinary) ? offeritems.scaleExternalOfferLogo :
                offeritems.maxExternalOfferLogo;
              logoAltText = offeritems.externalOfferLogoAlt;
              offerLink = visitorId != null && visitorId != "" ? (offeritems.externalOfferLink + "?FidID=" + visitorId) : offeritems.externalOfferLink;
              offerStickerName = (offeritems != null && offeritems.offer != null) ? offeritems.offer.sticker : "";
              retailerName = ((offeritems != null && offeritems.offer != null && offeritems.offer.title != null && offeritems.offer.title != "") ? offeritems.offer.title : "");
            }
            var offerdesc = { value: offeritems.offer.description }
            var offerterms = { value: offeritems.offer.termsAndConditions }
            var offerImageClass = model.isOffersComponent && offeritems.offer.image != "" ? "offers-header-back-img " :
              model.isOffersComponent && offeritems.offer.image == "" ? "offers-header" : "header";
            var tooltipClass = model.isOffersComponent && offeritems.offer.image != "" ? "offers-tooltip-btn-wrap-back" :
              model.isOffersComponent && offeritems.offer.image == "" ? "offers-tooltip-btn-wrap" : "offers-tooltip-btn-wrap";
            var offerImage = model.isOffersComponent && offeritems.offer.image != "" ? "d-flex offer-img-new w-100 p-0 " : "d-flex home-offers-image"
            var thumbnailClass = (model.isOffersComponent || (isClubPage && isMobileView)) ? "offers-thumbnail" : "thumbnail";
            var captionClass = (model.isOffersComponent || (isClubPage && isMobileView)) ? "offers-caption" : "caption";

            return (
              <div key={index} className={styleClassName + " col-12 flip-card px-lg-2 contentBlock"} data-link={offerLink} data-testid='testclick1' onClick={e => { fnGtmOfferClick(offerTitle, storeName, offerRetailerName, offeritems.eligibilityText, isDetailPage, model.isNewBrandedSite); offersClick(e, offerLink, isDetailPage) }} aria-label={offeritems.offer.dealId} data-istop={offeritems.offer.featured} data-letterfilter={offeritems.letterForFilter}>
                <div className={(thumbnailClass) + " thumbnail align-items-start flip-front position-relative border-8 p-0 shadow-none  remove-flip " + (model.isUSUK ? landingOfferModel.uS_UK_StoreLogo : "")}>
                  <a className="icon-heart-unfill-rw bookmark-news-new bookmark picto  ava-wishlist position-absolute" role="button" href="javascript:void(0);"
                    aria-label={offeritems.offer.dealId} aria-labelledby="deal" data-itemlabel={offerTitle} data-storename={storeName} data-retailerName={offerRetailerName}
                    data-templatetype={currentPage === "store" ? "Store Detail" : (
                      currentPage === "restaurant" ? "Restaurant Detail" : (
                        currentPage === "stores" ? "Stores List" : (
                          currentPage === "restaurants" ? "Restaurants List" : (
                            currentPage === "news" ? "News List" : (
                              currentPage === "offers" ? "Offers List" : (
                                currentPage === "news-detail" ? "News Detail" : ""
                              )
                            )
                          )
                        )
                      )
                    )}>
                  </a>
                  <a className={(offerImageClass) + " header w-100  d-flex bg-white position-relative cursor-default " + ((isMobileView && isClubPage) ? " club-mobile-logo" : "")}>
                    <>
                      {isOfferLogo && offeritems.offer.featured ?
                        (<img src={logoSource} loading="lazy" className={offerImage} alt={logoAltText} title={logoAltText}></img>) :
                        (
                          isOfferLogo ?
                            (<img src={logoSource} className={offerImage}  loading="lazy"  alt={logoAltText} title={logoAltText}></img>) :
                            (
                              offeritems.storeitem != null && offeritems.storeitem.storeTypeIds != null ?
                                (<span className="picto icon-ico-ph-shops"></span>) :
                                (<></>)
                            )
                        )
                      }
                      {model.isNewBrandedSite ?
                        (offeritems.eligibilityText != null && offeritems.eligibilityText != "" && baseModel.westfieldClubLogo?.value !== null && baseModel.westfieldClubLogo?.value !== "" && baseModel.westfieldClubLogo?.value.src !== null && baseModel.westfieldClubLogo?.value?.src !== "" &&
                          <div className='westfield-club-logo d-flex align-items-center justify-content-center w-100'>
                            <img className="d-flex wclub-img" src={baseModel.westfieldClubLogo?.value?.src} loading="lazy"
                              alt={baseModel.westfieldClubLogo?.value?.alt} title="club" />
                          </div>
                        ) :
                        (offeritems.eligibilityText != null && offeritems.eligibilityText != "" && baseModel.clubLogo?.value !== null && baseModel.clubLogo?.value !== "" && baseModel.clubLogo?.value.src !== null && baseModel.clubLogo?.value?.src !== "" &&
                          <div className='westfield-club-logo d-flex align-items-center justify-content-center w-100'>
                            <img className="d-flex wclub-img" src={baseModel.clubLogo?.value?.src} loading="lazy"
                              alt={baseModel.clubLogo?.value?.alt} title="club" />
                          </div>
                        )
                      }
                    </>
                  </a>
                  <div className={(captionClass +" cursor-default")}>
                    <div className='text-start retailer-name my-1'>{OfferStoreName}</div>
                    <div className='d-flex align-items-center'>
                      <div className='offer-title-new my-1 ' onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>{offeritems.offer.title}</div>
                      {model.isEuropeanSite ?
                        (
                          (offeritems.offer.description != null && offeritems.offer.description != "") &&
                          <div className={"picto icon-information-rw fs-5 position-relative rounded-circle js-tooltip ms-2 cursor-pointer"}>
                          </div>
                        ) :
                        (
                          (offeritems.offer.termsAndConditions != null && offeritems.offer.termsAndConditions != "") &&
                          <div className={" picto icon-information-rw fs-5 position-relative rounded-circle js-tooltip ms-2 cursor-pointer"}>

                          </div>
                        )
                      }
                    </div>
                    {offeritems.commercialOperationData !== null ?
                      (
                        <>
                          {offeritems.commercialOperationData?.map((category, index) => {
                            return (
                              <span key={index} className="offer-status p-0 fw-bold d-flex align-items-center">
                                {(category != null && category.categoryPicto != null && category.categoryPicto != "") &&
                                  <img src={category.categoryPicto}  loading="lazy"  alt={category.categoryName}></img>
                                }
                                <span className={"text-decoration-none"}>{category.categoryName}</span>
                              </span>
                            )
                          })
                          }

                          {(offeritems.commercialOperationNoPicto != null && offeritems.commercialOperationNoPicto != "") &&
                            <span className="offer-status p-0 fw-bold d-flex align-items-center">
                              <span className={"text-decoration-none"}>{offeritems.commercialOperationNoPicto}</span>
                            </span>
                          }
                        </>

                      ) :
                      (
                        <a className={"offer-status p-0 fw-bold d-flex align-items-center"}><span className="text-decoration-none"></span></a>
                      )
                    }
                    {offeritems.displayDateFront &&
                      <div className='date-offer text-start my-1'>{offeritems.validity}</div>
                    }
                    <div className='d-flex align-items-lg-center align-items-start discount-block my-2'>
                      {offeritems.offer.sticker !== null && offeritems.offer.sticker !== "" && offeritems.offer.sticker !== undefined &&
                        <div className='discount-new rounded-3'>{offeritems.offer.sticker}</div>
                      }
                      {offeritems.offer.subtitle !== null && offeritems.offer.subtitle !== "" && offeritems.offer.subtitle !== undefined &&
                        <div className={'discount-description text-start p-lg-2 ' + (offeritems.offer.sticker !== null && offeritems.offer.sticker !== "" && offeritems.offer.sticker !== undefined ? "" : " p-0")}><RichText field={subtitle} /></div>
                      }
                    </div>
                    {(!model.isEuropeanSite && offeritems.offer.description !== null && offeritems.offer.description !== "") &&
                      <div className={'discount-description text-start  my-1'}><RichText field={offerdesc} /></div>
                    }
                    
                  </div>
                </div>
                <div className={(thumbnailClass) + " align-items-start  bg-white flip-back position-relative border-8 p-1 " + (model.isUSUK ? landingOfferModel.uS_UK_StoreLogo : "")} style={{ display: "none" }}>
                  {model.isEuropeanSite ?
                    (
                      model.isNewBrandedSite ?
                        (<span className="flip-message test-start"><RichText field={offerdesc} /></span>) :
                        (<div className="flip-message test-start"><RichText field={offerdesc} /></div>)
                    ) :
                    (
                      model.isNewBrandedSite ?
                        (<span className="flip-message test-start"><RichText field={offerterms} /></span>) :
                        (<div className="flip-message test-start"><RichText field={offerterms} /></div>)
                    )
                  }
                  <span className="flip-close picto icon-close-circle-rw "> </span>
                </div>
              </div>
            )
          }

        })}

        {(model != null && (model.offerItems == undefined || model.offerItems == null || model.offerItems.length < 1)) &&
          <div className="picto-wrap emptystate">
            <span className="picto icon-servicesaspictosoffers"></span>
            <p className="text-uppercase">{landingOfferModel.offerEmptyText}</p>
          </div>
        }

        {(model != null && model.showViewMore) &&
          <><input type="hidden" id="firstviewmorecount" value={model.offerItems?.length} />
            <input type="hidden" id="totalviewmorecount" value={model.totalOffersCount} /></>
        }
      </>
    )
  }
}
