import loadable from "@loadable/component";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Cookies from "universal-cookie";
import {
  CommonBookmarkallpage,
  URWindowLocation,
  closeFlip,
  flip,
  fnApplyPyjamaEffect,
  fnClickNavigation,
  fnGtmOfferClick,
  fnUpdatePageNameInDataAttr,
  isMobile,
  lazyLoadingImg,
  offersTooltipOnHover
} from "../Js-CommonFunctions";
import { baseModel } from "../../RouteHandler.js";
import { useSelector } from "react-redux";
import axios from "axios";
const PersonalizedBlock = loadable(() => import('../PersonalizedBlock/index.js'), { ssr: false });

export default function HomePageOffers({ fields }) {
  var visitorId = useSelector((state) => state.user.visitorId)
  let workerCenter = useSelector((state) => state.user.workerCenter);
  var isLoggedIn = useSelector((state) => state.user.isLoggedIn)

  const [boolUseEffect, setBoolUseEffect] = useState(false); //some variables needs to be set and passed in second useEffect to overcome useEffect behaviour of not getting called in first render
  const [offersData, setOffersData] = useState();
  useEffect(() => {
    setBoolUseEffect(true);
    let isWorker =
      workerCenter !== undefined &&
      workerCenter !== '' &&
      workerCenter.split(',').includes(baseModel.pC_Code);
    if (isWorker || isLoggedIn) {
      var loader = document.getElementById("homepageoffersloader")

      if (loader) {
        loader.classList.remove("invisible");
        loader.classList.add("visible");
      }
      var ajaxServicesURL =
        "/api/sitecore/OffersApi/GetHomePageoffers?ursite=" +
        baseModel.urContextSite +
        "&urlang=" +
        baseModel.contextLang +
        "&isWorker=" +
        isWorker +
        "&isLoggedIn=" +
        isLoggedIn

      axios({
        method: "GET",
        url: ajaxServicesURL,
        async: true,
      })
        .then(function (response) {
          if (response?.data && response?.data?.offerItems?.length > 0) {
            setOffersData(response.data);
            if (document.getElementById("homePageOffers"))
              document.getElementById("homePageOffers").style.display = "block";
          }
          else {
            if (document.getElementById("homePageOffers"))
              document.getElementById("homePageOffers").style.display = "none";
            fnApplyPyjamaEffect();
          }
          if (loader) {
            loader.classList.add("invisible");
            loader.classList.remove("visible");
          }

        })
        .catch(function (error) {
          console.log(error);
          if (loader) {
            loader.classList.add("invisible");
            loader.classList.remove("visible");
          }
        });
    }
    else {
      if (fields.homePageOffers.offerItems !== null && fields.homePageOffers.offerItems !== "" && fields.homePageOffers.offerItems !== undefined && fields.homePageOffers.offerItems.length > 0) {
        setOffersData(fields.homePageOffers);
      }
      else {
        if (document.getElementById("homePageOffers"))
          document.getElementById("homePageOffers").style.display = "none";
        fnApplyPyjamaEffect();
      }
    }
    if (window.location.href.indexOf("offerslogin") >= 0) {
      var heightHeader = document.getElementById("headerBlock").offsetHeight + document.getElementById("MainHeader").offsetHeight;
      var rect = document.querySelector('#homePageOffers').getBoundingClientRect();
      if (isMobile.any()) {
        window.scrollTo({
          top: rect.top + window.scrollY + 400 - heightHeader, behavior: "smooth",
        });
      }
      else {
        window.scrollTo({
          top: rect.top + window.scrollY - 30 - 550 - heightHeader, behavior: "smooth",
        });
      }
    }
  }, []);

  useEffect(() => {
    var delay = 1000;
    setTimeout(function () {
      CommonBookmarkallpage();
      //lazyLoadingImg();
      offersTooltipOnHover();
      document
        .querySelectorAll("#homePageOffers .gtmOffersNavigation")
        .forEach((item) => {
          item.addEventListener("click", function (event) {
            //Bind the GTM click event for click navigations in this offers component
            fnUpdatePageNameInDataAttr(event.currentTarget, "gtmclickposition");
          });
        });

      document
        .querySelectorAll("#homePageOffers .gtmClickNavigation")
        .forEach((item) => {
          item.addEventListener("click", function (event) {
            //Bind the GTM click event for click navigations in this offers component
            fnUpdatePageNameInDataAttr(event.currentTarget, "gtmclickposition");
            fnClickNavigation(event.currentTarget);
          });
        });
    }, delay);
  }, [boolUseEffect, offersData]);

  var totalSlidesCount = fields.homePageOffers?.offerItems?.length + 1;
  var settings = {
    customPaging: function (i) {
      return (
        <button
          type="button"
          className="bg-main"
          data-role="none"
          role="button"
          tabIndex="0"
        />
      );
    },
    arrows: false,
    dots: false,
    autoplay: false,
    mobileFirst: true,
    infinite: false,
    afterChange: () => {
      lazyLoadingImg();
    },
    responsive: [
      {
        breakpoint: 10000, // a unrealistically big number to cover up greatest screen resolution
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: totalSlidesCount > 1 ? 1.3 : 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (
    fields !== null && fields !== undefined && fields !== undefined
  ) {
    var leftlink = fields?.homePageOffers?.leftLink;
    var rightlink = fields?.homePageOffers?.rightLink;
    var isNewBrandedSite = fields?.homePageOffers?.isNewBrandedSite;
    return (
      <section
        className={
          ' back-gray offers homePageOffers position-relative ' +
          (isNewBrandedSite === true ? ' ' : '') +
          (fields.homePageOffers?.displayPictureForOffers === true ? ' ' : '')
        }
        id="homePageOffers"
      >
        <div className='container'>
          <div
            className=
            'header-title d-flex align-items-baseline flex-wrap justify-content-lg-between '
          >
            <a
              aria-label={fields.homePageOffers?.personalizedOffers}
              className={
                ' title ' +
                (isNewBrandedSite === true ? '' : '') +
                ' gtmClickNavigation'
              }
              href={leftlink}
            >
              {fields.homePageOffers?.personalizedOffers != null &&
                fields.homePageOffers?.personalizedOffers != '' && (
                  <>
                    {isNewBrandedSite === true ? (
                      <h2>
                        <span className='news-title'>
                          {fields.homePageOffers?.personalizedOffers + " *"}
                        </span>
                      </h2>
                    ) : (
                      <h2>{fields.homePageOffers?.personalizedOffers + " *"}</h2>
                    )}
                  </>
                )}
            </a>
          </div>
          {offersData !== null && offersData !== undefined && offersData !== undefined &&
            offersData.offerItems !== null &&
            offersData.offerItems.length > 0 &&
            <> <Slider {...settings} className=" row carousel-offers slider-block d-lg-flex" id='carouselOffer'>
              {renderHomepageOffers(visitorId, offersData)}
            </Slider>
              <div className='more-info'>
                {offersData.cezOffer}
              </div>
            </>
          }
          <div className="loadersmall asyncloader invisible" id="homepageoffersloader">
            <div className="dot-spin top-left"></div>
          </div>
          <div className="d-flex justify-content-center my-3">
            <a className="link-button link-button-font btn-width mb-lg-0 border border-1 border-dark rounded-pill mb-3" href={rightlink}>{fields?.homePageOffers?.seeAllOffers} <span className="button-color">{"(" + offersData?.totalOffersCount + ")"}</span></a>
          </div>
        </div>
        {/* <button className="">{offersData?.totalOffersCount}</button> */}

      </section>
    );
  } else {
    return <></>;
  }
}

function fnOffersClick(e, offer, offersData, link) {
  var element = e.target;
  if (element.classList.contains("icon-heart-unfill-rw")) {
    //if clicked on the 'heart' icon, Unbookmark the item and do not redirect
    return;
  }
  if (element.classList.contains("js-tooltip")) {
    //if clicked on the 'i' icon, flip the block to show the description
    flip(e);
    return;
  }
  if (element.classList.contains("icon-close-circle-rw")) {
    //close of the flip to hide the description and show offer block
    var displayStyle = "block";
    closeFlip(e, displayStyle);
    return;
  }
  if (e.target.classList.contains('offers-test')) {
    fnGtmOfferClick(offer.offer.title, offer?.storeitem?.name, offer.storeitem?.retailerName, offer.eligibilityText, false, offersData.isNewBrandedSite);
    URWindowLocation(link)
  }
}

function renderHomepageOffers(visitorId, offersData) {
  let counter = 0;
  const cookies = new Cookies();
  var personlizedblockDislpalyed = false;
  var userLogged = visitorId !== null && visitorId !== "" && visitorId !== undefined ? true : false;
  //cookies.get(offersData.fidCookieName) != null ? true : false;
  var isNewBrandedSite = offersData.isNewBrandedSite;

  let offersBlock = [];
  {
    offersData.offerItems
      .map((offer, index) => {
        var externalOfferLink =
          visitorId !== null &&
            visitorId !== ""
            ? offer.externalOfferLink + '?FidID=' + visitorId //cookies.get(offersData.fidCookieName)
            : offer.externalOfferLink;
        var isExternalOffer =
          offer.commercialOperation != null &&
            offer.commercialOperation.categoryId === offersData.commercialExternalType
            ? true
            : false;
        var strCommercialOperation =
          offer.commercialOperation != null && offer.commercialOperation.name != null
            ? offer.commercialOperation.name
            : '';
        var strEligibility = offer.strEligibility;
        var desc = { value: offer.offer?.description };
        var offerterms = { value: offer.offer.termsAndConditions }
        counter++;
        var fullwidthImgClass = offersData.isUSUK ? "d-flex uk-us-offer-img-new" : "d-flex offer-img-new img-fluid p-0 ";
        var logoClass = offersData.isUSUK ? "d-flex uk-us-home-offers-image" : "d-inline-block home-offers-image ";
        const link =
          isExternalOffer === false && offer.storeLinkURL != null && offer.storeLinkURL != ''
            ? offersData.urlPrefix + offer.storeLinkURL + '#offers'
            : externalOfferLink;
        if (
          offersData.homePageOffersBlockPosition === counter &&
          userLogged === false &&
          offersData.isLoyaltyDisabled === false
        ) {
          offersBlock.push(
            <div
              key={index}
              className={
                'col-6 col-lg-4 personal-offer-new px-3 bg-white col-md-4 mt-2 mb-3 d-flex justify-content-between flex-column ' +
                (isNewBrandedSite === true ? '' : 'offer-wrap-new') +
                (offersData.isUSUK === true &&
                  offersData.displayImageinHomePage === false
                  ? ' us-uk'
                  : '')
              }
            >
              <PersonalizedBlock model={offersData} />
            </div>
          );
          personlizedblockDislpalyed = true;
        }
        offersBlock.push(
          <div
            className={
              'col-6 col-lg-4 col-md-4 position-relative  px-lg-0 mt-2 mb-3 offer-wrap-new  flip-card offers-test' + // Adding "offers-test" class to prevent onClick trigger 2 times
              (isNewBrandedSite === true ? '' : '') +
              (offersData.displayImageinHomePage === true ? '' : '') +
              (isExternalOffer === false ? ' gtmOffersNavigation' : '') +
              (counter > 3 ? ' d-lg-none ' : '') +
              (offersData.isUSUK === true &&
                offersData.displayImageinHomePage === false
                ? ' us-uk'
                : '')
            }
            onClick={(e) => fnOffersClick(e, offer, offersData, link)}
          >
            <div className="offers-thumbnail  align-items-start flip-front homepage-flip-front position-relative border-0 rounded-0 p-0 shadow-none remove-flip">
              {userLogged && (
                <>
                  {offer.offerType != null ? (
                    <input type="hidden" id={'offerType'} value={offer.offerType} />
                  ) : (
                    <input type="hidden" id="offerType" value="Regular" />
                  )}
                </>
              )}
              <a
                className={'icon-heart-unfill-rw bookmark-news-new bookmark picto  ava-wishlist position-absolute'}
                style={{ zIndex: '2' }}
                href="#"
                aria-label={offer.offer.dealId}
                aria-labelledby="deal"
                data-itemlabel={offer.offer.title}
                data-templatetype="Home Center"
                data-storename={offer.storeitem != null ? offer.storeitem.name : ''}
                data-retailerName={offer.storeitem != null ? offer.storeitem.retailerName : ''}
                role="button"
              ></a>

              <a
                aria-label={offersData.displayImageinHomePage}
                className={"header " + (offer.hasLogo ? "justify-content-center" : " ") + " position-relative border-8 bg-white"}
                // {
                //   (offersData.displayImageinHomePage === false
                //     ? 'brand-picture-wrap-new bg-white'
                //     : ' brand-image-wrap position-relative') + ' d-inline-block w-100'
                // }
                href="#"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  return e.currentTarget.closest('.offer-wrap-new').click();
                }}
              >
                {offer.hasLogo &&
                  <span className="helper"></span>
                }
                {offer.offerImage != null && offer.offerImage != '' ? (
                  <img
                    className={(offer.hasLogo ? logoClass : fullwidthImgClass)}
                    src={offer.offerImage}
                    loading="lazy"
                    alt={offer.imgLogoAltText}
                    title={offer.imgLogoAltText}
                  />
                ) : (
                  <>
                    {offer.storeitem != null && offer.storeitem.storeTypeIds.length > 0 && (
                      <>
                        {offer.storeitem.storeTypeIds.includes(offersData.typeStore) ? (
                          <span className={'picto icon-ico-ph-shops'}></span>
                        ) : (
                          <span className={'picto icon-srv-restaurant'}></span>
                        )}
                      </>
                    )}
                  </>
                )}
                {
                  isNewBrandedSite ?
                    (offer.eligibilityText !== null && offer.eligibilityText !== '' && baseModel.westfieldClubLogo?.value !== null && baseModel.westfieldClubLogo?.value !== "" && baseModel.westfieldClubLogo?.value.src !== null && baseModel.westfieldClubLogo?.value?.src !== "" &&
                      <div className="westfield-club-logo d-flex align-items-center justify-content-center w-100">
                        <img className="d-flex wclub-img p-0" src={baseModel.westfieldClubLogo?.value?.src} loading="lazy"
                          alt={baseModel.westfieldClubLogo?.value?.alt} title="club" />
                      </div>
                    )
                    :
                    (offer.eligibilityText !== null && offer.eligibilityText !== '' && baseModel.clubLogo?.value !== null && baseModel.clubLogo?.value !== "" && baseModel.clubLogo?.value.src !== null && baseModel.clubLogo?.value?.src !== "" &&
                      <div className="westfield-club-logo d-flex align-items-center justify-content-center w-100">
                        <img className="d-flex wclub-img p-0" src={baseModel.clubLogo?.value?.src} loading="lazy"
                          alt={baseModel.clubLogo?.value?.alt} title="club" />
                      </div>
                    )
                }
              </a>
              <a className='offer-content bg-transparent mt-2 d-block text-center' role="button">
                <div
                  className='text-start retailer-name my-1'
                  onClick={(e) => {
                    e.preventDefault();
                    return e.currentTarget.closest('.offer-wrap-new').click();
                  }}
                >
                  {offer.storeitem != null && <>{offer.storeitem.name}</>}
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className={'offer-title-new my-1'}
                    onClick={(e) => {
                      e.preventDefault();
                      return e.currentTarget.closest('.offer-wrap-new').click();
                    }}
                  >
                    {offer.offer.title}
                  </div>
                  {offersData.isEuropeanSite ?
                    (
                      (offer.offer?.description != null && offer.offer?.description != "") &&
                      <div className="picto icon-information-rw fs-5 position-relative rounded-circle js-tooltip mx-2"></div>
                    )
                    :
                    (
                      (offer?.offer.termsAndConditions != null && offer?.offer.termsAndConditions != "") &&
                      <div className="picto icon-information-rw fs-5 position-relative rounded-circle js-tooltip mx-2"></div>
                    )}
                </div>
                {offer.displayDateFront &&
                  <div className="date text-start my-1"
                    onClick={(e) => {
                      e.preventDefault();
                      return e.currentTarget.closest('.offer-wrap-new').click();
                    }}
                  >
                    {offer.validity}
                  </div>
                }
                <div className="d-flex discount-block">
                  {offer.offer.sticker !== null && offer.offer.sticker !== "" && offer.offer.sticker !== undefined &&
                    <span
                      className=
                      'discount-new rounded-3'
                      onClick={(e) => {
                        e.preventDefault();
                        return e.currentTarget.closest('.offer-wrap-new').click();
                      }}
                    >
                      {offer.offer.sticker}
                    </span>
                  }
                  {offer.offer.subtitle !== null && offer.offer.subtitle !== "" && offer.offer.subtitle !== undefined &&
                    <span
                      className={'discount-description text-start' + (offer.offer.sticker !== null && offer.offer.sticker !== "" && offer.offer.sticker !== undefined ? "" : " p-0")}
                      // role="button"
                      // href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        return e.currentTarget.closest('.offer-wrap-new').click();
                      }}
                    >
                      {offer.offer.subtitle}
                    </span>
                  }
                </div>
                {offersData.isUSUK && offer.offer.description != null && offer.offer.description != '' && (
                  <div
                    className={
                      'discount-description text-start p-0 my-1'
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      return e.currentTarget.closest('.offer-wrap-new').click();
                    }}
                  >
                    <RichText field={desc} />
                  </div>
                )}
              </a >
              <div className="text-start d-flex align-items-center bg-transparent  my-2" >
                <a aria-label={offersData.homePageOffersText} href="#" className="grid-new-cta pe-1" onClick={(e) => {
                  e.preventDefault();
                  return e.currentTarget.closest('.offer-wrap-new').click();
                }}> {offersData.homePageOffersText}</a>
                <span className="picto icon-arrow-square-red-right-rw align-middle px-0 fs-5" />
              </div >
            </div>
            <div className={"offers-thumbnail align-items-start  bg-white flip-back home-flip-back position-relative border-8 p-1 w-100"} style={{ display: "none" }}>
              {offersData.isEuropeanSite ?
                (
                  (<span className="flip-message test-start"><RichText field={desc} /></span>)
                ) :
                (
                  (<div className="flip-message test-start"><RichText field={offerterms} /></div>)
                )
              }
              <span className="flip-close picto icon-close-circle-rw "> </span>
            </div>
          </div >
        );
        if (
          ((offersData.offerItems.length === 1 &&
            userLogged === false &&
            personlizedblockDislpalyed === false) ||
            (offersData.offerItems.length === counter &&
              personlizedblockDislpalyed === false &&
              userLogged === false)) &&
          offersData.isLoyaltyDisabled === false
        ) {
          offersBlock.push(
            <div
              className={
                'col-6 col-lg-4 personal-offer-new px-3 bg-white col-md-4 mt-2 mb-3 d-flex justify-content-between flex-column ' +
                (isNewBrandedSite ? '' : 'offer-wrap-new ') +
                (offersData.isUSUK === true &&
                  offersData.displayImageinHomePage === false
                  ? ' us-uk'
                  : '')
              }
            >
              <PersonalizedBlock model={offersData} />
            </div>
          );
        }
      });
  }
  return offersBlock;
}
